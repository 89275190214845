<template>
	<div>
		<button class="btn btn-secondary btn-sm" @click="backToList()"><span class="mr-2 pointer"><v-icon class="align-middle" name="angle-left"></v-icon></span>Back to dataset list</button>
		<h4 class="text-center">Dataset {{datasetId}}</h4>
		<p class = "text-center mt-3"><pulse-loader :loading="loading" color="#18BC9C"></pulse-loader></p>

		<div v-if="!loading">
			<div style = 'position: relative'>
				<ul class="mt-3 nav nav-tabs" v-if="dataset && dataset.h5ad">
					<li class="pointer nav-item" @click="changeNav('dataset')" > <a class="nav-link" :class="(nav=='dataset')?'active':''">Infos</a> </li>
					<li class="pointer nav-item" @click="changeNav('plot')" > <a class="nav-link" :class="(nav=='plot')?'active':''">UMAP Plot</a> </li>
					<li class="pointer nav-item" @click="changeNav('exp')" > <a class="nav-link" :class="(nav=='exp')?'active':''">Gene X vs Gene Y expression</a> </li>
					<!-- <li class="pointer nav-item" @click="changeNav('bulk')" > <a class="nav-link" :class="(nav=='bulk')?'active':''">Gene set expression</a> </li> -->
				</ul>
				<button type = 'button' class="btn btn-primary" style="position: absolute; right: 0px; top: 0px" @click="downloadH5ad" :disabled="downloadingH5ad"  v-if="dataset && dataset.h5ad"><v-icon :name="(downloadingH5ad)?'spinner':'download'"></v-icon><span class="ml-1">{{downloadingH5ad?"dowloading, please be patient...":".h5ad file"}}</span></button>
			</div>
			<div v-if="dataset">
				<div class="row" v-if="nav =='dataset'">
					<div class="p-3" :class="cat.placement" v-for="cat in categories" :key="cat.name">
						<div class="card pr-3">
							<h4 class="text-center my-2">{{cat.name}}</h4>
							<dl class="row">
								<template v-for="field in visibleFields(cat.fields)">
									<dt v-if="dataset[field]" class="ddright" :class = "(cat.name==='Publications')?'col-3':'col-6'" :key="`dt_${field}`">{{obj_keys[field].display}}</dt>
									<dd v-if="dataset[field]" class="" :class="(field=='Abstract') ? 'col-9 justify' : (cat.name==='Publications') ? 'col-9': 'col-6'" :key="`dd_${field}`"> <scrnaseq-fields :row="dataset" :field='field'></scrnaseq-fields></dd>
								</template>
								<dt v-if="cat.yes_no" class="col-6 ddright" >Available data</dt>
								<dd v-if="cat.yes_no" class="col-6">
									<span v-for="(a_data,a_idx) in cat.yes_no" :key="`yesNo${a_idx}`">

										<span v-if="obj_keys[a_data] && obj_keys[a_data].display.trim().length && a_idx">, </span><span v-if="obj_keys[a_data] && obj_keys[a_data].display.trim().length">{{obj_keys[a_data].display.replace(" available","")}}</span>
									</span>
								</dd>
							</dl>
						</div>
					</div>
				</div>

				<template v-if="dataset && dataset.h5ad">

					<div class="mt-3 d-flex justify-content-center" v-if="nav=='plot'">
						<div class="" style = 'position: relative; width: 80%;' v-if="matrix.headers">
							<div class = 'form-group row' style = 'position: absolute; top: 0; width: 300px; z-index: 10; left: 25%'>
								<div class = 'col-4 text-right'><label class="form-input-label" for="colorUmap">Color by: </label></div>
								<div class = 'col-8'><select class="form-control" v-model="umapField" @change="changeColorUmap()"><option v-for="f in umapFields" :key="f">{{f}}</option></select></div>
							</div>
							<div class = 'form-group row' style = 'position: absolute; top: 0px; width: 500px; z-index: 10; left: 0%' v-if="matrix.data.length>10000">
								<div class = 'col-5 text-right'><label for="subsetSize" class="p-1" :class="maxcells?'bg-danger text-light':''">Plot subset of cells: </label></div>
								<div class="col-3 text-right"><select class="form-control form-control-sm" @change="setLimit($event)"><option value="10000"  :selected="maxcells===10000">10000</option><option value="0" :selected="maxcells===0">-All cells-</option></select></div>
							</div>
							<div class="row">
								<div class="col-12 pt-3" :class="expandUMAP?'col-md-12':'col-md-6'" style="position: relative">
									<button type="button" style="position:absolute; left: 10px; top: 10px; z-index: 1000" class="btn btn-sm btn-link" @click="expandUMAP=!expandUMAP" v-b-tooltip.hover :title="expandUMAP?'compress plot':'expand plot'"><v-icon :name="expandUMAP?'compress':'expand'"></v-icon></button>
									<chart :options="chartOptionsMatrix" :style="`height: ${windowHeight/2}px; width: 100%;`" :autoresize="true"></chart>
								</div>
								<div class="col-12 col-md-6" v-if="expandUMAP===false">
									<patient-page :dataset-id="datasetId" :annotation='umapField'></patient-page>
								</div>
							</div>
							<div class="row" v-if="gene_dist">
								<div class="col-12"  :class="expandUMAP?'col-md-12':'col-md-6'" :style="`visibility: ${geneLoading?'hidden':'visible'}`">
									<div v-if="gene_dist" style="position: absolute; top: 0; width: 80%; text-align: center; left: 0%; z-index: 10000"><h4>{{gene_dist}} <button type="button" class="btn btn-link btn-sm ml-1"  @click="gene_dist=''"><v-icon name="times" /></button></h4></div>
									<chart :options="chartOptionsUmapGene" :style="`height: ${windowHeight/2}px; width: 100%`" :autoresize="true"></chart>
								</div>
								<div class="col-12 col-md-6" v-if="expandUMAP===true">
									<patient-page :dataset-id="datasetId" :annotation='umapField'></patient-page>
								</div>
								<div class="col-12 col-md-6">
									<h4>{{`${gene_dist} expression distribution in ${umapField.replace('_',' ')}`}}</h4>
									<gene-violin :gene='gene_dist' :group='gene_dist_group' :annotation='umapField' :ready="!geneLoading"></gene-violin>
								</div>
							</div>
							<div v-if="umapField.indexOf('annotation_') > -1 && geneExpr.data.length">
								<ul class="nav nav-tabs">
									<li class="nav-item">
										<a class="nav-link" :class="exprView==='raw'?'active':''" @click.prevent="exprView='raw'">Average gene expression</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" :class="exprView==='diff'?'active':''" @click.prevent="exprView='diff'">Differential gene expression</a>
									</li>
								</ul>

								<div v-if="exprView==='raw'">
									<h4 class="my-3">
										Average gene expression in <code>{{umapField.replace("_"," ")}}</code>
										<download-csv
										class   = "btn btn-outline-secondary ml-2 float-right"
										:data   = "jsonData"
										:name    = "`${datasetId}_${umapField}.csv`">
										download CSV
									</download-csv>

									<span class="float-right">
										<b-form-input
										v-model="datasetExprTableFilter"
										type="search"
										id="datasetExprTableFilter"
										placeholder="filter"
										debounce="100"
										></b-form-input>
									</span>
								</h4>
								<b-table
								class="table-sm"
								style="font-size: 0.8rem"
								id="datasetExprTable"
								:items="geneExprData"
								:fields="geneExprFields"
								:per-page="perPage"
								:current-page="currentPage"
								selectable
								select-mode="single"
								@row-clicked="selectGene"
								small
								>
								<template v-slot:table-busy>
									<div class="text-center text-info my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>Loading...</strong>
									</div>
								</template>
								<template v-slot:cell(0)="data">
									<strong>{{data.value}}</strong>
									<button type = 'button' class="btn btn-link btn-sm" @click="displayGeneDist(data.value)" v-b-tooltip.hover title="show violin graph"><img src="../assets/icon_violin.png" width="16" height="16"></button>
								</template>
								<template v-slot:cell()="data">
									<span v-html="formatTableCell(data)"></span>
								</template>
							</b-table>

							<b-pagination
							v-model="currentPage"
							:total-rows="geneExpr.data.length"
							:per-page="perPage"
							aria-controls="datasetExprTable"
							></b-pagination>

						</div>
						<div v-if="exprView==='diff'">

							<h4 class="my-3">
								Differentially expressed gene in cluster <b-form-select v-model="selectedDiffCluster" :options="diffClusters" class="inlineSelect"></b-form-select> from  <code>{{umapField.replace("_"," ")}}</code>
								<download-csv
								class   = "btn btn-outline-secondary ml-2 float-right"
								:data   = "jsonDEData"
								:name    = "`${datasetId}_${umapField}.csv`">
								download CSV
							</download-csv>
						</h4>
						<b-table
						class="table-sm"
						style="font-size: 0.8rem"
						id="datasetDETable"
						:busy.sync="isBusyDETable"
						:items="getDEItems"
						:fields="geneDEFields"
						selectable
						select-mode="single"
						@row-clicked="selectGene"
			      :current-page="currentDEPage"
			      :per-page="perDEPage"
						small
						@sort-changed="currentDEPage=1"
						>
						<template v-slot:table-busy>
							<div class="text-center text-info my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Loading...</strong>
							</div>
						</template>
						<template v-slot:cell(0)="data">
							<strong>{{data.value}}</strong>
							<button type = 'button' class="btn btn-link btn-sm" @click="displayGeneDist(data.value)" v-b-tooltip.hover title="show violin graph"><img src="../assets/icon_violin.png" width="16" height="16"></button>
						</template>
						<template v-slot:cell()="data">
							<span v-html="formatTableCell(data)"></span>
						</template>
					</b-table>

					<b-pagination
					v-model="currentDEPage"
					:total-rows="totalDERows"
					:per-page="perDEPage"
					size="sm"
					class="my-0"
					aria-controls="datasetDETable"
					></b-pagination>

				</div>
			</div>
		</div>
	</div>

	<div v-if="nav=='exp'">
		<h5 class="mt-3 text-center">Compare expression of two genes</h5>
		<div class="col-6 offset-3">
			<div class = 'card'>
				<div class = 'card-body'>
					<div class="row nowrap form-group mt-3">
						<!-- <div class = 'col-2 text-right'><label for="gene1">Gene 1</label></div> -->
						<div class = 'col-3'><vue-bootstrap-typeahead v-model="gene1" size="sm" placeholder="Gene 1" :data="dataset_genes" input-class="search" ref="gene1"/></div>
						<!-- <div class = 'col-2 text-right'><label for="gene2">Gene 2</label></div> -->
						<div class = 'col-1 text-center'>vs</div>
						<div class = 'col-3'><vue-bootstrap-typeahead v-model="gene2" size="sm" placeholder="Gene 2" :data="dataset_genes" input-class="search" ref="gene2"/></div>
						<div class = 'col-2'><button class="btn btn-info btn-sm" @click="comparegeneComp()" :disabled="!gene1 || !gene2 || gene1 === gene2 || geneCompLoading">{{geneCompLoading?"Computing...":"Compare"}}</button></div>
						<div class = 'col-2'><button class="btn btn-link btn-sm" @click="comparegeneComp('ex')">example</button></div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-3 d-flex justify-content-center" v-if="geneCompStatus">
			<div class="" style = 'position: relative; width: 800px;margin:auto;' v-if="geneComp.headers">
				<div class = 'form-group row' style = 'position: absolute; right: 0; top: 0; width: 300px; z-index: 10'>
					<div class = 'col-4 text-right'><label for="colorExpr">Color by: </label></div>
					<div class = 'col-8'><select class="form-control" id = "colorExpr" v-model="exprField" @change="changeColorExpr()"><option v-for="f in exprFields" :key="f">{{f}}</option></select></div>
					<div class="col-12 text-right"><small>ρ=Pearson correlation (ignoring cells with 0 count)</small></div>
				</div>
				<chart :options="chartOptionsgeneComp" style="width: 800px; height: 600px"></chart>
			</div>
		</div>
		<p class = "text-center mt-3"><pulse-loader :loading="geneCompLoading" color="#18BC9C"></pulse-loader></p>
		<h5 v-if="geneCompStatus" class="my-3">
			For each cell type, Venn diagrams counting the number of cells expressing {{gene1}} and/or {{gene2}}. Pie charts represent the proportion of cells expressing one of the two genes.
					<button type="button" class="btn btn-sm btn-link float-right hideDownloads" @click="download" v-b-tooltip.hover title="download plots as PNG"><v-icon name="download" /></button>
		</h5>
		<div class="row row-cols-4 mb-2"  v-if="geneCompStatus"  ref="venneDiagrams">
			<div class = 'col' v-for="(cellType) in diffExprCellTypes" :key="cellType">
				<div class="card" v-if="cells[exprField]!==undefined">
					<div class="card-body">
						<div class="card-title">
							<strong>{{cellType}}</strong> ({{cells[exprField][cellType]}}) <span class="float-right text-muted"><fischer :data="dataFischer(cellType)" type="right"></fischer></span>
						</div>
						<div class="card-text" style="position: relative">
							<venn-chart :data="computeVennChart(cellType)" :title="cellType"></venn-chart>
							<div style="position: absolute; width: 20px; right: 0px; bottom: -8px"><pie-chart :data="computePie(cellType)" :cell-type="cellType" size='35'></pie-chart></div>
							<!-- <small style="position: absolute; width: 80px; right: 25px; bottom: 0px; text-align: right; cursor: help" v-b-tooltip.hover :title="`number of ${cellType} cells expressing ${gene1} or ${gene2} / total number of ${cellType} cells`"> {{nbCellsFromComp(cellType)}}</small> -->
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<div v-if="nav==='bulk'">
		<bulk-rna :dataset-id="datasetId"></bulk-rna>
	</div>
</template>

</div>

</div>

</div>
</template>

<script>

import { HTTP } from '@/router/http'
import { mapGetters } from 'vuex'
import scrnaseqFields from '@/components/Fields.vue'
import geneViolin from '@/components/geneViolin'
import bulkRna from '@/components/bulkRna'
import patientPage from '@/components/patientPage'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import palette from 'google-palette'
import JsonCSV from 'vue-json-csv'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import vennChart from '@/components/vennchart'
import pieChart from '@/components/pieChart'
import fischer from '@/components/fischer'
import html2canvas from 'html2canvas'
function corr (d1, d2) {
	let { min, pow, sqrt } = Math
	let add = (a, b) => a + b
	let n = min(d1.length, d2.length)
	if (n === 0) {
		return 0
	}
	[d1, d2] = [d1.slice(0, n), d2.slice(0, n)]
	let [sum1, sum2] = [d1, d2].map(l => l.reduce(add))
	let [pow1, pow2] = [d1, d2].map(l => l.reduce((a, b) => a + pow(b, 2), 0))
	let mulSum = d1.map((n, i) => n * d2[i]).reduce(add)
	let dense = sqrt((pow1 - pow(sum1, 2) / n) * (pow2 - pow(sum2, 2) / n))
	if (dense === 0) {
		return 0
	}
	return (mulSum - (sum1 * sum2 / n)) / dense
}
let geneMatrix = []
export default {
	name: 'ScrnaseqDetails',
	props: ['datasetId', 'obj_keys'],
	components: { geneViolin, vennChart, pieChart, fischer, scrnaseqFields, bulkRna, patientPage, VueBootstrapTypeahead, downloadCsv: JsonCSV, PulseLoader },
	data () {
		return {
			nav: 'plot',
			expandUMAP: false,
			loading: false,
			downloadingH5ad: false,
			categories: null,
			dataset: null,
			exprView: 'raw',
			perPage: 25,
			currentPage: 1,
			datasetExprTableFilter: '',
			datasetDETableFilter: '',
			perDEPage: 25,
			currentDEPage: 1,

			isBusyDETable: false,
			geneExprData: [],
			gene1: '',
			gene2: '',
			gene_dist: '',
			gene_dist_group: 'annotation_immune',
			gene_dist_status: false,
			geneCompStatus: false,
			geneCompLoading: false,
			umapField: 'annotation_major',
			exprField: 'annotation_major',
			windowHeight: 0,
			chartOptionsMatrix: {
				title: { text: 'UMAP', subtext: 'no sort', left: 'center' },
				grid: {
					right: '200px'
				},
				visualMap: [
					{
						type: 'piecewise',
						dimension: 2,
						categories: [],
						inverse: true,
						right: 0,
						top: '50px',
						itemWidth: 15,
						itemHeight: 10,
						itemGap: 4
					}
				],
				xAxis: { name: 'UMAP_1' },
				yAxis: { name: 'UMAP_2' },
				series: [],
				toolbox: {
					left: 0,
					top: 30,
					showTitle: true,
					feature: {
						saveAsImage: {
							show: true,
							name: `${this.datasetId}_UMAP_plot`,
							title: 'save as image'

						}
					}
				}
			},
			chartOptionsUmapGene: {
				title: { text: 'UMAP', left: 'center', top: 0 },
				grid: {
					right: '200px'
				},
				visualMap: [
					{
						type: 'continuous',
						dimension: 3,
						color: ['#bf444c', '#d88273', '#f6efa6'],
						// color: ["#FCFFA4","#F7D03C","#FB9A06","#ED6925","#CF4446","#A52C60","#781C6D","#4B0C6B","#1B0C42","#000004"],
						right: 0,
						top: '50px'
					}
				],
				xAxis: { name: 'UMAP_1' },
				yAxis: { name: 'UMAP_2' },
				series: [],
				toolbox: {
					left: 0,
					top: 30,
					showTitle: true,
					feature: {
						saveAsImage: {
							show: true,
							name: `${this.datasetId}_UMAP_plot_${this.gene_dist}`,
							title: 'save as image'

						}
					}
				}
			},

			chartOptionsgeneComp: {
				tooltip: {
					showDelay: 0,
					formatter: this.tooltipFormattergeneComp,
					axisPointer: { show: true, type: 'cross', lineStyle: { type: 'dashed', width: 0.5 } }
				},
				grid: {
					right: '200px'
				},
				visualMap: [
					{
						type: 'piecewise',
						dimension: 2,
						// categories: [],
						inverse: true,
						pieces: [],
						inRange: {
							color: ['rgb(244, 67, 54)', 'rgb(233, 30, 99)', 'rgb(156, 39, 176)', 'rgb(103, 58, 183)', 'rgb(63, 81, 181)', 'rgb(33, 150, 243)', 'rgb(3, 169, 244)', 'rgb(0, 188, 212)', 'rgb(0, 150, 136)', 'rgb(76, 175, 80)', 'rgb(139, 195, 74)', 'rgb(205, 220, 57)', 'rgb(255, 235, 59)', 'rgb(255, 193, 7)', 'rgb(255, 152, 0)', 'rgb(255, 87, 34)', 'rgb(121, 85, 72)', 'rgb(158, 158, 158)', 'rgb(96, 125, 139)']
						},
						right: 0,
						top: '80px',
						itemWidth: 15,
						itemHeight: 10,
						itemGap: 4

					}
				],
				xAxis: { name: 'gene 1' },
				yAxis: { name: 'gene 2' },
				series: [],
				toolbox: {
					left: 0,
					top: 0,
					showTitle: true,
					feature: {
						saveAsImage: {
							show: true,
							name: `${this.datasetId}_gene_comp`,
							title: 'save as image'
						}
					}
				}
			},
			selectedDiffCluster: null

		}
	},
	computed: {
		...mapGetters({
			datasets: 'scrnaseq_datasets',
			matrix: 'matrix',
			dataset_genes: 'dataset_genes',
			geneComp: 'scrnaseqGeneComp',
			geneExpr: 'scrnaseqGeneExpr',
			geneDE: 'scrnaseqGeneDE',
			cells: 'scrnaseq_cells',
			maxcells: 'scrnaseq_sizelimit',
			parameters: 'scrnaseq_parameters',
			scrnaseq_gene_dist: 'scrnaseq_gene_dist',
			geneLoading: 'scrnaseq_gene_dist_loading'
		}),
		exprFields () {
			return _.filter(this.geneComp.headers, (h, i) => i > 3)
		},
		umapFields () {
			return _.filter(this.matrix.headers, h => h.indexOf('UMAP') === -1 && h !== 'unique_id')
		},
		geneExprFields () {
			let fields = []
			_.forEach(this.geneExpr.headers, (h, i) => {
				let className = (!i) ? 'nowrap' : ''
				fields.push({
					key: i + '',
					label: h,
					sortable: true,
					tdClass: className
				})
			})
			return fields
		},
		geneDEFields () {
			let fields = []
			_.forEach(this.geneDE.headers, (h, i) => {
				if (h === 'p_val') return
				const className = (!i) ? 'nowrap' : ''
				fields.push({
					key: i + '',
					label: h,
					sortable: true,
					tdClass: className
				})
			})
			return fields
		},
		diffClusters () {
			return _.keys(this.geneDE.data)
		},
		jsonData () {
			return [this.geneExpr.headers].concat(this.geneExpr.data)
		},
		jsonDEData () {
			return [this.geneDE.headers].concat(this.geneDE.data[this.selectedDiffCluster])
		},
		diffExprCellTypes () {
			const _this = this
			let catIdx = _.findIndex(_this.geneComp.headers, function (h) { return h === _this.exprField })
			if (catIdx === -1) {
				_.forEach(_this.geneComp.headers, (h, hidx) => {
					if (h.indexOf('annot') > -1 && catIdx === -1) {
						catIdx = hidx
						_this.exprField = h
					}
				})
			}

			return _.filter(_.uniq(_.map(_this.geneComp.data, d => d[catIdx])))
		},
		totalDERows () {
			return this.geneDE.data[this.selectedDiffCluster].length
		},
		getDEItems () {
			let start = (this.currentDEPage - 1) * this.perDEPage
			return this.geneDE.data[this.selectedDiffCluster].slice(start, start + this.perDEPage)
		}

	},

	methods: {
		setLimit (evt) {
			const _this = this
			let limit = +evt.target.value
			this.$store.commit('SET_SCRNASEQ_LIMIT', limit)
			_this.$store.dispatch('getScrnaseqOneDataset', _this.datasetId).then(function (res) {
				_this.drawUmap()
				_this.loading = false
				if (_this.gene_dist) {
					_this.drawGeneUMAP()
				}
			}).catch(err => {
				_this.$snotify.error(err)
			})
		},
		nbCellsFromComp (cellType) {
			const _this = this
			let catIdx = _.findIndex(_this.geneComp.headers, function (h) { return h === _this.exprField })
			if (catIdx === -1) {
				_.forEach(_this.geneComp.headers, (h, hidx) => {
					if (h.indexOf('annot') > -1 && catIdx === -1) {
						catIdx = hidx
						_this.exprField = h
					}
				})
			}

			let data = _.filter(_this.geneComp.data, d => {
				return (d[catIdx] === cellType)
			})
			return data.length + ' / ' + this.cells[this.exprField][cellType]
		},
		computePie (cellType) {
			const values = this.nbCellsFromComp(cellType)
			const parts = values.split(' / ')
			return { total: +parts[1], noZero: +parts[0], zero: +parts[1] - +parts[0] }
		},
		downloadH5ad () {
			const _this = this
			_this.downloadingH5ad = true
			HTTP.get('scrnaseq/' + this.datasetId + '/h5ad', {
				responseType: 'arraybuffer'
			}).then(res => {
				let blob = new Blob([res.data], { type: 'application/binary' })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = _this.datasetId + '.h5ad'
				link.click()
				_this.downloadingH5ad = false
			})
		},
		selectGene (item) {
			this.gene_dist = item[0]
		},
		displayGeneDist (gene) {
			let _this = this
			_this.gene_dist = gene
			_this.gene_dist_group = _this.umapField
			// _this.$bvModal.show('geneDistModal')
		},
		changeColorUmap () {
			let _this = this
			let umapIdx = _.findIndex(_this.matrix.headers, function (h) { return h === _this.umapField })
			_this.chartOptionsMatrix.toolbox.feature.saveAsImage.name = `${this.datasetId}_UMAP_plot_${this.umapField}`
			if (umapIdx === -1) umapIdx = 0
			_this.chartOptionsMatrix.visualMap[0].dimension = umapIdx
			const visualMapType = (isNaN(this.matrix.data[1][umapIdx]) || _this.umapField === 'seurat_clusters') ? 'piecewise' : 'continuous'
			if (visualMapType === this.chartOptionsMatrix.visualMap[0].type) {
				if (visualMapType === 'piecewise') {
					_this.chartOptionsMatrix.visualMap[0].inverse = true
					// _this.chartOptionsMatrix.visualMap[0].categories = _.filter(_.uniq(_.map(_this.matrix.data, d => d[umapIdx])))
					_this.chartOptionsMatrix.visualMap[0].pieces = []
					let categories = _.filter(_.uniq(_.map(_this.matrix.data, d => d[umapIdx])), d => d !== undefined)
					_.forEach(categories, c => {
						const nb = _.filter(_this.matrix.data, d => d[umapIdx] === c).length
						_this.chartOptionsMatrix.visualMap[0].pieces.push({
							value: c,
							label: c + ' (' + nb + ')'
						})
					})
					_this.chartOptionsMatrix.visualMap[0].inRange = { color: palette('mpn65', _this.chartOptionsMatrix.visualMap[0].pieces.length).map(c => `#${c}`) }
				} else {
					_this.chartOptionsMatrix.visualMap[0].inverse = false
					let min = 0
					let max = 0
					_.forEach(this.matrix.data, d => {
						if (!min || d[umapIdx] < min) min = d[umapIdx]
						if (!max || d[umapIdx] > max) max = d[umapIdx]
					})
					_this.chartOptionsMatrix.visualMap[0].min = min
					_this.chartOptionsMatrix.visualMap[0].max = max
					_this.chartOptionsMatrix.visualMap[0].inRange = null
					_this.chartOptionsMatrix.visualMap[0].categories = null
					_this.chartOptionsMatrix.visualMap[0].pieces = null
					_this.chartOptionsMatrix.visualMap[0].color = ['#bf444c', '#d88273', '#f6efa6']
				}
			} else {
				_this.chartOptionsMatrix.visualMap[0].type = visualMapType
				if (visualMapType === 'piecewise') {
					_this.chartOptionsMatrix.visualMap[0].inverse = true
					// _this.chartOptionsMatrix.visualMap[0].categories = _.filter(_.uniq(_.map(_this.matrix.data, d => d[umapIdx])))
					_this.chartOptionsMatrix.visualMap[0].pieces = []
					let categories = _.filter(_.uniq(_.map(_this.matrix.data, d => d[umapIdx])), d => d !== undefined)
					_.forEach(categories, c => {
						const nb = _.filter(_this.matrix.data, d => d[umapIdx] === c).length
						_this.chartOptionsMatrix.visualMap[0].pieces.push({
							value: c,
							label: c + ' (' + nb + ')'
						})
					})

					_this.chartOptionsMatrix.visualMap[0].inRange = { color: palette('mpn65', _this.chartOptionsMatrix.visualMap[0].pieces.length).map(c => `#${c}`) }
				} else {
					let min = 0
					let max = 0
					_.forEach(this.matrix.data, d => {
						if (!min || d[umapIdx] < min) min = d[umapIdx]
						if (!max || d[umapIdx] > max) max = d[umapIdx]
					})
					_this.chartOptionsMatrix.visualMap[0].min = min
					_this.chartOptionsMatrix.visualMap[0].max = max
					_this.chartOptionsMatrix.visualMap[0].inRange = null
					_this.chartOptionsMatrix.visualMap[0].categories = null
					_this.chartOptionsMatrix.visualMap[0].pieces = null
					_this.chartOptionsMatrix.visualMap[0].color = ['#bf444c', '#d88273', '#f6efa6']
				}
			}
			if (_this.umapField.indexOf('annotation_') > -1) {
				const category = _this.umapField.replace('annotation_', '')
				_this.$store.dispatch('getScrnaseqGeneExpression', { dataset_id: _this.datasetId, category: category })
				_this.$store.dispatch('getScrnaseqGeneDE', { dataset_id: _this.datasetId, category: category }).then(() => {
					this.selectedDiffCluster = _.keys(this.geneDE.data)[0]
				})
			}
		},
		changeColorExpr () {
			let _this = this
			let catIdx = _.findIndex(_this.geneComp.headers, function (h) { return h === _this.exprField })
			if (catIdx === -1) {
				_.forEach(_this.geneComp.headers, (h, hidx) => {
					if (h.indexOf('annot') > -1 && catIdx === -1) {
						catIdx = hidx
						_this.exprField = h
					}
				})
			}

			const visualMapType = (isNaN(this.geneComp.data[1][catIdx]) || _this.exprField === 'seurat_clusters') ? 'piecewise' : 'continuous'
			if (visualMapType === this.chartOptionsgeneComp.visualMap[0].type) {
				if (visualMapType === 'piecewise') {
					_this.chartOptionsgeneComp.visualMap[0].pieces = []
					_this.chartOptionsgeneComp.visualMap[0].dimension = catIdx
					// _this.chartOptionsgeneComp.visualMap[0].categories = _.filter(_.uniq(_.map(_this.geneComp.data, d => d[catIdx])))
					let categories = _.filter(_.uniq(_.map(_this.geneComp.data, d => d[catIdx])), d => d !== undefined)
					_.forEach(categories, c => {
						let data = _.filter(_this.geneComp.data, d => {
							return (d[catIdx] === c && d[0] !== undefined && d[1] !== undefined)
						})
						let x = _.map(data, d => d[0])
						let y = _.map(data, d => d[1])
						const correlation = corr(x, y)

						_this.chartOptionsgeneComp.visualMap[0].pieces.push({
							value: c,
							label: c + ' (N=' + data.length + '; ρ=' + (Math.round(correlation * 100) / 100) + ')'
						})
					})

					_this.chartOptionsgeneComp.visualMap[0].inRange.color = palette('mpn65', categories.length).map(c => `#${c}`)
				}
			} else {
				_this.chartOptionsgeneComp.visualMap[0].type = visualMapType
				if (visualMapType === 'piecewise') {
					_this.chartOptionsgeneComp.visualMap[0].pieces = []
					_this.chartOptionsgeneComp.visualMap[0].dimension = catIdx
					let categories = _.filter(_.uniq(_.map(_this.geneComp.data, d => d[catIdx])), d => d !== undefined)
					_.forEach(categories, c => {
						let data = _.filter(_this.geneComp.data, d => {
							return (d[catIdx] === c && d[0] !== undefined && d[1] !== undefined)
						})
						let x = _.map(data, d => d[0])
						let y = _.map(data, d => d[1])
						const correlation = corr(x, y)

						_this.chartOptionsgeneComp.visualMap[0].pieces.push({
							value: c,
							label: c + ' (N=' + data.length + '; ρ=' + (Math.round(correlation * 100) / 100) + ')'
						})
					})
					_this.chartOptionsgeneComp.visualMap[0].inRange.color = palette('mpn65', categories.length).map(c => `#${c}`)
				}
			}
		},
		drawUmap () {
			let _this = this
			let umapIdx = _.findIndex(_this.matrix.headers, function (h) { return h === _this.umapField })
			if (umapIdx === -1) {
				_this.umapField = 'annotation_CHETAH'
				umapIdx = _.findIndex(_this.matrix.headers, function (h) { return h === _this.umapField })
				if (umapIdx === -1) {
					_this.umapField = 'seurat_clusters'
					umapIdx = _.findIndex(_this.matrix.headers, function (h) { return h === _this.umapField })
				}
				if (umapIdx > -1) {
					const category = _this.umapField.replace('annotation_', '')
					_this.$store.dispatch('getScrnaseqGeneExpression', { dataset_id: _this.datasetId, category: category })
					_this.$store.dispatch('getScrnaseqGeneDE', { dataset_id: _this.datasetId, category: category }).then(() => {
						this.selectedDiffCluster = _.keys(this.geneDE.data)[0]
					})
				}
			}
			_this.chartOptionsMatrix.toolbox.feature.saveAsImage.name = `${this.datasetId}_UMAP_plot_${this.umapField}`
			_this.chartOptionsMatrix.visualMap[0].pieces = []
			// _this.chartOptionsMatrix.visualMap[0].categories = _.filter(_.uniq(_.map(_this.matrix.data, d => d[umapIdx])))
			let categories = _.filter(_.uniq(_.map(_this.matrix.data, d => d[umapIdx])), d => d !== undefined)
			_.forEach(categories, c => {
				const nb = _.filter(_this.matrix.data, d => d[umapIdx] === c).length
				_this.chartOptionsMatrix.visualMap[0].pieces.push({
					value: c,
					label: c + ' (' + nb + ')'
				})
			})
			_this.chartOptionsMatrix.visualMap[0].inRange = { color: palette('mpn65', _this.chartOptionsMatrix.visualMap[0].pieces.length).map(c => `#${c}`) }
			// let subData = []
			// if (this.maxcells > 1000) {
			// 	const step = Math.ceil(_this.matrix.data.length / this.maxcells)
			// 	const dimData = _this.matrix.data.length
			// 	for (let i = 0; i < dimData; i = i + step) {
			// 		subData.push(_this.matrix.data[i])
			// 	}
			// } else subData = _this.matrix.data
			let series = {
				symbolSize: 4,
				data: _this.matrix.data,
				type: 'scatter',
				emphasis: {
					itemStyle: {
						shadowBlur: 5,
						shadowColor: 'rgba(24, 24, 24, 0.5)'
					}
				}
			}
			_this.chartOptionsMatrix.visualMap[0].dimension = umapIdx
			_this.chartOptionsMatrix.series = [series]
		},
		drawGeneUMAP () {
			// let subData = []
			// if (this.maxcells > 1000) {
			// 	const step = Math.ceil(geneMatrix.length / this.maxcells)
			// 	const dimData = geneMatrix.length
			// 	for (let i = 0; i < dimData; i = i + step) {
			// 		subData.push(geneMatrix[i])
			// 	}
			// } else subData = geneMatrix

			let series = {
				symbolSize: 4,
				data: geneMatrix,
				type: 'scatter',
				emphasis: {
					itemStyle: {
						shadowBlur: 5,
						shadowColor: 'rgba(24, 24, 24, 0.5)'
					}
				}
			}
			this.chartOptionsUmapGene.series = [series]
			this.chartOptionsUmapGene.toolbox.feature.saveAsImage.name = `${this.datasetId}_UMAP_plot_${this.gene_dist}`
		},
		tooltipFormatterMatrix (params) {
		// return 'tooltip'
		},
		tooltipFormattergeneComp (params) {
			let _this = this
			let catIdx = _.findIndex(_this.geneComp.headers, function (h) { return h === _this.exprField })
			return '<b>' + this.geneComp.gene1 + '</b>: ' + Math.round(params.value[0] * 100) / 100 + '<br/>' + '<b>' + this.geneComp.gene2 + '</b>: ' + Math.round(params.value[1] * 100) / 100 + '<br/>' + '<b>' + this.exprField + '</b>: ' + params.value[catIdx]
		},
		comparegeneComp (example) {
			let _this = this
			if (example) {
				_this.gene1 = 'CD8A'
				_this.gene2 = 'CD8B'
			}
			_this.geneCompStatus = false
			_this.geneCompLoading = true
			_this.$store.dispatch('getScrnaseqGeneComparison', { gene1: _this.gene1, gene2: _this.gene2 }).then(function () {
				_this.geneCompLoading = false
				_this.chartOptionsgeneComp.xAxis.name = _this.geneComp.gene1
				_this.chartOptionsgeneComp.yAxis.name = _this.geneComp.gene2
				let catIdx = _.findIndex(_this.geneComp.headers, function (h) { return h === _this.exprField })

				if (catIdx === -1) {
					_.forEach(_this.geneComp.headers, (h, hidx) => {
						if (h.indexOf('annot') > -1 && catIdx === -1) {
							catIdx = hidx
							_this.exprField = h
						}
					})
				}
				// _this.chartOptionsgeneComp.visualMap[0].categories = _.filter(_.uniq(_.map(_this.geneComp.data, d => d[catIdx])))
				let categories = _.filter(_.uniq(_.map(_this.geneComp.data, d => d[catIdx])), d => d !== undefined)
				_.forEach(categories, c => {
					let data = _.filter(_this.geneComp.data, d => {
						return (d[catIdx] === c && d[0] !== undefined && d[1] !== undefined)
					})
					let x = _.map(data, d => d[0])
					let y = _.map(data, d => d[1])
					const correlation = corr(x, y)

					_this.chartOptionsgeneComp.visualMap[0].pieces.push({
						value: c,
						label: c + ' (N=' + data.length + '; ρ=' + (Math.round(correlation * 100) / 100) + ')'
					})
				})
				_this.chartOptionsgeneComp.visualMap[0].inRange.color = palette('mpn65', categories.length).map(c => `#${c}`)
				let series = {
					symbolSize: 6,
					data: _this.geneComp.data,
					type: 'scatter',
					emphasis: {
						itemStyle: {
							shadowBlur: 5,
							shadowColor: 'rgba(24, 24, 24, 0.5)'
						}
					}
				}
				_this.chartOptionsgeneComp.visualMap[0].dimension = catIdx
				_this.chartOptionsgeneComp.series = [series]
				_this.gene1 = _this.geneComp.gene1
				_this.gene2 = _this.geneComp.gene2
				_this.$refs.gene1.inputValue = _this.gene1
				_this.$refs.gene2.inputValue = _this.gene2

				_this.geneCompStatus = true
			})
		},
		changeNav (newNav) {
			this.nav = newNav
		},
		visibleFields (fields) {
			let hiddenFields = [
				'disease_ontology_id_purl',
				'PMID',
				'Cancer_type_abbreviation'
			]
			return _.filter(fields, f => hiddenFields.indexOf(f) === -1)
		},
		backToList () {
			this.dataset_id = null
			this.$router.push('/')
		},
		formatTableCell (data) {
			const value = data.value
			if (data.field.label === 'NCBIGeneID') {
				return "<a href='https://www.ncbi.nlm.nih.gov/gene/" + value + "' target='_blank'>" + value + '</a>'
			}
			if (!isNaN(value)) {
				return value
			}
			if (value.indexOf('ENSG') > -1) {
				return "<a href='https://www.ensembl.org/Homo_sapiens/Gene/Summary?db=core;g=" + value + "' target = '_blank'>" + value + '</a>'
			}
			if (value.indexOf('HGNC') > -1) {
				return "<a href='https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/" + value + "' target = '_blank'>" + value + '</a>'
			}
			if (value.match(/[OPQ][0-9][A-Z0-9]{3}[0-9]|[A-NR-Z][0-9]([A-Z][A-Z0-9]{2}[0-9]){1,2}/)) {
				return "<a href='https://www.uniprot.org/uniprot/" + value + "' target = '_blank'>" + value + '</a>'
			}
			return value
		},
		computeVennChart (cellType) {
			const _this = this
			let catIdx = _.findIndex(_this.geneComp.headers, function (h) { return h === _this.exprField })
			if (catIdx === -1) {
				_.forEach(_this.geneComp.headers, (h, hidx) => {
					if (h.indexOf('annot') > -1 && catIdx === -1) {
						catIdx = hidx
						_this.exprField = h
					}
				})
			}


			let data = _.filter(_this.geneComp.data, d => {
				return (d[catIdx] === cellType)
			})
			let A = _.filter(data, d => d[0])
			let B = _.filter(data, d => d[1])
			let AB = _.filter(data, d => d[1] && d[0])
			return [
				{ sets: [_this.gene1], size: A.length },
				{ sets: [_this.gene2], size: B.length },
				{ sets: [_this.gene1, _this.gene2], size: AB.length }
			]
		},
		dataFischer (cellType) {
			const sets = this.computeVennChart(cellType)
			
			if (sets.length) {
				const total = this.cells[this.exprField][cellType]
				// let total = 0
				// _.forEach(sets, s => {
				// 	total += s.size
				// })
				const data = [sets[2].size, (sets[1].size - sets[2].size), sets[0].size - sets[2].size, total - sets[2].size - (sets[0].size - sets[2].size) - (sets[1].size - sets[2].size)]
				return data
			}
			return []
		},
		download () {
			const _this = this
			_.forEach(document.getElementsByClassName('hideDownloads'), e => {
				e.style.display = 'none'
			})
			html2canvas(this.$refs.venneDiagrams, { allowTaint: true, scrollX: 0, scrollY: -window.scrollY }).then(function (canvas) {
				var link = document.createElement('a')

				if (typeof link.download === 'string') {
					link.href = canvas.toDataURL()
					link.download = _this.gene1 + '_vs_' + _this.gene2 + '_venn_plots.png'

					// Firefox requires the link to be in the body
					document.body.appendChild(link)

					// simulate click
					link.click()

					// remove the link when done
					document.body.removeChild(link)
				} else {
					window.open(uri)
  			}
				_.forEach(document.getElementsByClassName('hideDownloads'), e => {
					e.style.display = 'inline-block'
				})
			})
		}


	},
	mounted () {
		let _this = this
		this.windowHeight = window.innerHeight
		_this.categories = _this.datasets.categories
		_this.dataset = _.find(_this.datasets.data, function (d) { return d.unique_id === _this.datasetId })
		this.gene_dist = ''
		if (_this.dataset && _this.dataset.h5ad) {
			_this.loading = true
			_this.$store.dispatch('getScrnaseqParams', { dataset_id: _this.datasetId })
			if (_this.umapField.indexOf('annotation_') > -1) {
				const category = _this.umapField.replace('annotation_', '')
				_this.$store.dispatch('getScrnaseqGeneExpression', { dataset_id: _this.datasetId, category: category })
				_this.$store.dispatch('getScrnaseqGeneDE', { dataset_id: _this.datasetId, category: category }).then(() => {
					this.selectedDiffCluster = _.keys(this.geneDE.data)[0]
				})
			}
			_this.$store.dispatch('getScrnaseqOneDataset', _this.datasetId).then(function (res) {
				_this.drawUmap()
				_this.loading = false
			}).catch(err => {
				_this.$snotify.error('TST ' + err)
			})
		} else if (!_this.dataset) {
			_this.$snotify.error('the dataset ' + _this.datasetId + "doesn't exist.")
			setTimeout(function () {
				_this.$router.push('/')
			}, 3000)
		} else {
			_this.nav = 'dataset'
		}
	},
	watch: {
		gene_dist: function (n, o) {
			const _this = this
			if (n && n !== o) {
				this.$store.dispatch('getScrnaseqGeneDistribution', { gene: n, dataset: _this.datasetId })
			}
		},
		'scrnaseq_gene_dist.gene' () {
			const _this = this
			_this.chartOptionsUmapGene.title.text = this.gene_dist
			geneMatrix = []
			let min = 0
			let max = 0

			_.forEach(_this.scrnaseq_gene_dist.data, (r, rIdx) => {
				const searchKey = (r.unique_id !== undefined) ? r.unique_id : (r.index !== undefined) ? r.index : ''
				if (searchKey !== _this.matrix.data[rIdx][2]) {
					// console.log('ERROR at ' + rIdx + ': ' + r.unique_id + ' => ' + _this.matrix.data[rIdx][2])
				} else {
					if (!min || r.value < min) min = r.value
					if (!max || r.value > max) max = r.value
					geneMatrix.push([_this.matrix.data[rIdx][0], _this.matrix.data[rIdx][1], searchKey, r.value])
				}
			})
			geneMatrix.sort(sortFunction)

			function sortFunction (a, b) {
			    if (a[3] === b[3]) {
			        return 0
			    } else {
			        return (a[3] < b[3]) ? -1 : 1
			    }
			}
			_this.chartOptionsUmapGene.visualMap[0].min = min
			_this.chartOptionsUmapGene.visualMap[0].max = max
			_this.chartOptionsUmapGene.visualMap[0].text = [Math.round(max * 100) / 100, Math.round(min * 100) / 100]
			this.drawGeneUMAP()
		},
		'geneExpr.data': function () {
			this.geneExprData = this.geneExpr.data
			// this.gene_dist = ''
		},
		datasetExprTableFilter (n, o) {
			if (!n) {
				this.geneExprData = this.geneExpr.data
				this.currentPage = 1
			} else {
				const searchKey = n.toUpperCase()
				this.currentPage = 1
				this.geneExprData = _.filter(this.geneExpr.data, (d, i) => {
					return d[0].toUpperCase().indexOf(searchKey) > -1
				})
			}
		},
		gene_dist_status (n, o) {
			if (n !== o && n) {
				setTimeout(() => this.drawGeneDist(), 200)
			}
		}
	}
}

</script>
<style scoped>
.inline{
display:inline-block;
}
.card-columns {
column-count: 3;
}
.scrnaseq_container{
padding: 0 2%;
}
.ddright{
text-align:right;
}
ul {
padding-left:0;
list-style-type: none;
}
td.nowrap {
	white-space: nowrap;
}
.inlineSelect {
	display: inline-block;
	width: 200px;
}
.venn {
	margin-left: 120px;
	width: 200px;
	height: 100px;
	padding: 10px;
	font-size: 12px;
	font-weight: bold;
	position: relative;

}
</style>
