<template>
	<span>
		<span v-if="value=='N'"><span><v-icon class="align-middle" name="ban" color="red"></v-icon></span></span>
		<span v-else-if="value=='Y'"><span><v-icon class="align-middle" name="check-circle" color="green"></v-icon></span></span>
		<span v-else-if="value && field=='Cancer_type'">{{value}} ({{row['Cancer_type_abbreviation']}})</span>
		<span v-else-if="value && field=='Date'">{{value|formatDate}} </span>
		<span v-else-if="value!==undefined && field=='search'">
			<span v-if="Number.isInteger(value)">{{value}}</span>
			<span v-else-if="value">{{+value["annotation_"+annotation]}}</span>
			<span v-else>{{value}}</span>
		</span>
		<span v-else-if="value && field=='DOI'"> <a :href="'https://doi.org/'+value" target="_blank">{{value}}</a> </span>
		<span v-else-if="value && value.startsWith('GSE')">
			<a :href="'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc='+value" target="_blank">{{value}}</a>
		</span>
		<span v-else-if="value && value.startsWith('DOID:')">
			<a :href="'http://purl.obolibrary.org/obo/'+value" target="_blank">{{value}}</a>
		</span>
		<span v-else-if="value && value.startsWith('EGAD')">
			<a :href="'https://ega-archive.org/datasets/'+value" target="_blank">{{value}}</a>
		</span>
		<span v-else-if="value && value.startsWith('EGAS')">
			<a :href="'https://ega-archive.org/studies/'+value" target="_blank">{{value}}</a>
		</span>
		<span v-else-if="value && value.startsWith('http')">
			<a :href="value" target="_blank">{{value}}</a>
		</span>
		<span v-else-if="value && value.startsWith('http')">
			<a :href="value" target="_blank">{{value}}</a>
		</span>
		<span v-else-if="field=='Enrichment_markers' || field=='Treatment_type'">
			<ul v-if="value"><li v-for="(val,midx) in splitField(value)" :key="midx">{{val}}</li></ul>
			<!-- <ul v-if="value" :class="(value.indexOf(',')>-1) ? '':'list-unstyled'"><li v-for="(val,midx) in splitField(value)" :key="midx">{{val}}</li></ul> -->
		</span>
		<span v-else-if="field==='Abstract'">
			{{viewAbstract(value)}} <button type = 'button' class = 'btn btn-link btn-sm' v-if="!showFullAbstract" @click="showFullAbstract=!showFullAbstract">show more...</button>
		</span>
		<span v-else-if="value">{{value}}</span>
		<span v-else>-</span>
	</span>
</template>

<script>
export default {
	name: 'scrnaseqFields',
	props: ['row', 'field', 'annotation'],
	data () {
		return {
			showFullAbstract: false
		}
	},
	computed: {
		value () {
			return this.row[this.field]
		}
	},

	methods: {
		splitField: function (markers) {
			return (markers) ? markers.split(',') : null
		},
		viewAbstract (text) {
			if (this.showFullAbstract) return text
			let sentences = text.trim().split('.')
			let len = 0
			let trimText = ''
			_.forEach(sentences, s => {
				if (len + s.length < 600) trimText += s + '.'
				len += s.length
			})
			return trimText
		}
	},
	mounted () {
	}
}

</script>
<style scoped>
.inline{
	display:inline-block;
}
.card-columns {
	column-count: 3;
}
.scrnaseq_container{
	padding: 0 2%;
}
.ddright{
	text-align:right;
}
ul {
	padding-left:0;
	list-style-type: none;
}
</style>
