
<template>
	<div>
		<b-modal ref="modalConfirmDownload" size="md" hide-footer title="Confirm download">
			<div>
				<p class="text-center"> The size of the zip file to download is around {{selectedH5adSize}}MB.<br>Are you sure to want to download it ?</p>
				<p class="text-center">				
					<button class="btn btn-outline-success mr-2" @click="downloadAction('confirm')" :disabled="download.loading">Confirm</button>
					<button class="btn btn-outline-primary" @click="downloadAction('cancel')" :disabled="download.loading">Cancel</button>
				</p>
				<!-- <p class="text-center text-warning" v-if="download.loading"><em>Download will be triggered in few seconds</em></p> -->
			</div>
		</b-modal>
	
		<div v-if="loading.global" style="padding:0 20%"> <loading></loading> </div>

		<div v-else-if="!raw_data.length&&!loading.global" style="padding:0 20%">
		
			<div class="card">
				<div class="card-header bg-danger">Error</div>
				<div class="card-body text-center">
					<p class="card-text">Sorry, there is an error to get publications. Try to reload this page please.</p>
				</div>
			</div>
		</div>
		<div class = 'scrnaseq_container' v-else-if='!loading.global'>
			<div v-if="dataset_id">
				<scrnaseq-details :dataset-id="dataset_id" :obj_keys='obj_keys'></scrnaseq-details>
			</div>
			<div v-if="!dataset_id">

				<h4 class="text-center"> {{raw_data.length}} publications <span v-if="filteredData.length < raw_data.length">in total, {{filteredData.length}} filtered</span>
					<small v-if="latest_sync">
						<br>
						( latest release @ {{latest_sync.date}}
						<!-- <button type = 'button' class="btn btn-sm btn-outline-primary" v-b-tooltip.hover title="synchronize by uploading excel file" @click="uploadExcelFile()" v-if="!upload.status"><v-icon class="align-middle" name="sync-alt" v-if="!loading.sync"></v-icon><v-icon class="align-middle" name="sync-alt" v-else spin></v-icon>&nbsp;new version...</button> -->
						)
						<!-- <button type = 'link' class="btn btn-link"  @click="downloadScRNAseqDb"> <v-icon name='file-excel' scale='0.8'></v-icon> download</button> -->
					</small>
				</h4>


				<div v-if="upload.status" class="mt-3">
					<vue-clip :options="upload.options" :on-complete="onComplete">
					<template slot="clip-uploader-action">
						<div>
							<div class="dz-message"><h2> Click or Drag and Drop the <code><b>.xlsx</b></code> file of the database here to upload </h2></div>
						</div>
						<p class="text-center"> <button class="btn btn-sm btn-secondary mb-2" @click="uploadExcelFile()">Cancel</button> </p>

					</template>
				</vue-clip>

			</div>

			<div v-else :class="(loading.sync) ? 'loading_class':''">
				<div class="d-flex justify-content-between w-100" >
					<div>
						<div>							
							<div class="custom-control custom-switch float-right ml-3">
								<input type="checkbox" v-model="displayOnlyData" class="custom-control-input" id="onlyH5ad">
								<label class="custom-control-label" for="onlyH5ad">Display only datasets with integrated data</label>
							</div>
							<button class="btn btn-sm btn-info mb-2" @click="advancedFilters()" v-if="!ad_filters.status">advanced filters</button>
						</div>
						<button class="btn btn-sm btn-outline-primary" @click="downloadAction('init')" v-if="!download.status && !ad_filters.status" :disabled="download.loading"><span class="mr-2"><v-icon class="align-middle" name="download"></v-icon></span>download</button>
						<template v-if="download.status && !ad_filters.status">
							<button class="btn btn-sm btn-outline-success" v-if="selectedData.length" @click="downloadAction('download','selected')" :disabled="download.loading">Download selected <span class="ml-1 badge badge-success">{{selectedData.length}}</span></button>
							<button class="btn btn-sm btn-outline-success" v-else :disabled="true">select datasets to download</button>
							<button class="btn btn-sm btn-outline-success ml-2" @click="downloadAction('download','all')" :disabled="download.loading">All datasets <span class="ml-1 badge badge-success" >{{total_h5ad.nb}}</span></button>
							<button :disabled="download.loading" class="btn btn-sm btn-outline-primary ml-2" v-if="!download.loading" @click="downloadAction('cancel')">Cancel</button>
							<p v-if="download.loading" class="text-info mt-2"> <span class="mr-2"><b-spinner variant="primary" style="width: 1.3rem; height: 1.3rem;"></b-spinner></span> <em>Download in progress</em></p>

						</template>
					</div>
					
					<div><h4 class="outline-info" v-if="search.name && !search.status">Filter by {{search.cas}}: {{formattedSearch}} <button type = "button" class="btn btn-link" @click="resetSearch">reset</button></h4></div>
					<div>
						<span v-if="!search.status && !ad_filters.status">

							<template>
								<button class="btn btn-sm btn-outline-success float-right mb-2 mr-2" @click="searchGeneOrCell('init','gene')">search by gene</button>
								<button class="btn btn-sm btn-outline-success float-right mb-2 mr-2" @click="searchGeneOrCell('init','cell')">search by cell type</button>
							</template>
							
							<select class="form-control form-control-sm mx-2 float-right" style="display: inline; width: 200px" v-model="annotation" v-if="(formattedSearch && search.cas==='gene') || search.cas==='cell' ">
								<option :value="annot" v-for="annot in annotationTypes" :key="annot">annotation {{annot}}</option>
							</select>
							<div class="custom-control custom-switch float-right" v-if="(formattedSearch && search.cas==='gene')">
								<input type="checkbox" v-model="heatmapScaled" class="custom-control-input" id="heatmapScaled">
								<label class="custom-control-label" for="heatmapScaled">Center / Scale dataset values</label>
							</div>

						</span>

						<span v-if="search.status" class="">
							<form @submit.prevent.stop="searchGeneOrCell('search')" style="display:inline-flex" class="mb-2">
								<input v-if="search.cas=='gene'" type="text" class="mr-2 form-control form-control-sm" v-model="search.name" placeholder="gene symbol" ref="geneSearch">
								<select class="form-control form-control-sm mx-2 float-right" style="display: inline; width: 200px" v-model="annotation" v-if=" search.cas==='cell' ">
									<option :value="annot" v-for="annot in annotationTypes" :key="annot">annotation {{annot}}</option>
								</select>
								<select v-if="search.cas=='cell'" class="mr-2 form-control form-control-sm" v-model="search.name" ref="cellSearch"><option v-for="ct in cv_cell_types" :key="ct">{{ct}}</option></select>
								<!-- <select v-if="search.cas=='cell'" class="mr-2 form-control" v-model="search.name" @change="searchGeneOrCell('search')"><option v-for="ct in cv_cell_types">{{ct}}</option></select> -->

								<button type="submit" class="btn btn-sm btn-outline-success mr-2" :disabled="!search.name">search</button>
								<button type="button" class="btn btn-sm btn-outline-secondary mr-2" @click="searchGeneOrCell('cancel')">cancel</button>
							</form>
						</span>
					</div>
				</div>

				<div v-if="ad_filters.status" class="my-4" style="padding:0 15%;">
					<div class="card px-2 py-3" style="background-color:#E1F6F1">
						<h5 class=""><span class="pointer mr-3" @click="advancedFilters()"><v-icon class="align-middle" name="times-circle"></v-icon></span>Advanced filters</h5>
						<div class="row mt-1" v-for="(ad_f,lidx) in ad_filters.list" :key="lidx">

							<div class="offset-2 col-sm-3">
								<select class="form-control" v-model="ad_f.id">
									<optgroup v-for='k in categories' :key='k.name' :label='k.name'>
										<option v-for="f in k.fields" :key="f" :value="f">{{f.replace(/_/g," ")}}</option>
										<template v-if="k.yes_no">
											<option v-for="f in k.yes_no" :key="f" :value="f">Is {{f.replace(/_/g," ").replace(" available","")}} available ?</option>
										</template>
									</optgroup>
								</select>
							</div>
							<div class="col-sm-1" v-show="structure[ad_f.id] !=='boolean'">
								<select class="form-control" v-model="ad_f.comp"><template v-for="comp in ad_filters.comparators" ><option :key="comp" v-if="structure[ad_f.id]==='float' || (comp!=='>='&&comp!='<=')" :value="comp">{{comp}}</option></template></select>
							</div>
							<div class="col-sm-3">
								<div v-if="filters[ad_f.id]">
									<div v-if="structure[ad_f.id]==='boolean'">
										<div class="btn-group" role="group">
											<button type="button"  @click="filters[ad_f.id].value='Y';filterAction();"  class="btn" :class="(filters[ad_f.id].value==='Y')?'btn-success':'btn-outline-success'">Yes</button>
											<button type="button"  @click="filters[ad_f.id].value='N';filterAction();"  class="btn" :class="(filters[ad_f.id].value==='N')?'btn-danger':'btn-outline-danger'">No</button>
										</div>
									</div>
									<select v-else-if="ad_f.comp==='='" class="form-control" v-model="filters[ad_f.id].value" @change="filterAction()"><option v-for="c in choices[ad_f.id]" :key="c" :value="c">{{c}}</option></select>
									<input v-else-if="ad_f.comp==='like'" class="form-control" v-model="filters[ad_f.id].value" type="text" @input="filterAction()">
									<input v-else class="form-control" v-model="filters[ad_f.id].value" type="number" @input="filterAction()">
								</div>
							</div>
							<div class="col-sm-2">
								<button style="display:inline-block" v-if="filters[ad_f.id] && filters[ad_f.id].value" class="btn btn-sm btn-primary mr-1" @click="removeFilter(ad_f.id,lidx)"><span><v-icon class="align-middle" name="minus-circle"></v-icon></span></button>
								<button style="display:inline-block" v-if="lidx===ad_filters.list.length-1 && filters[ad_f.id] && filters[ad_f.id].value" class="btn btn-sm btn-primary" @click="addFilter()"><span><v-icon class="align-middle" name="plus-circle"></v-icon></span></button>
							</div>
						</div>
					</div>
				</div>

				<div v-if='formattedSearch && search.cas === "gene" && !search.status'>
					<gene-dataset-heatmap :gene="formattedSearch" :annotation="annotation" :filteredDatasets="filteredDatasets" :scaled="heatmapScaled"></gene-dataset-heatmap>
				</div>
				<div style="position: relative; z-index: 100">
					<table class="table table-sm mt-3">
						<thead>
							<tr>
								<th></th>
								<th class="nowrap text-center" v-for="(field,fidx) in fields.main" :key="fidx">{{obj_keys[field].display}}</th>
							</tr>
							<tr>
								<th>
									<div class="custom-control custom-checkbox mr-2" v-if="download.status" style="display:inline-flex">
										<input type="checkbox" class="custom-control-input" @click="selectRow('global')" id="customBatchCheckSelectGlobal" v-model="download.all">
										<label class="custom-control-label" for="customBatchCheckSelectGlobal">&nbsp;</label>
									</div>
									<span class="pointer" v-b-tooltip.hover title="Reset filters" @click="resetFilters()"><v-icon class="align-middle" name="sync-alt"></v-icon></span>
								</th>
								<th v-for="(field,fidx) in fields.main" :key="fidx">
									<select v-if="field === 'Cell_amount'" class="form-control form-control-sm filterWithComp" v-model="filters[field].comp" @change="filterAction('table')"><template v-for="comp in ad_filters.comparators" ><option :key="comp" :value="comp">{{comp}}</option></template></select>
									<template v-if="isTypeahead(field)">
										<!-- <vue-bootstrap-typeahead
											v-model="filters[field].value"
											size="sm"
											:placeholder="`search ${obj_keys[field].display}...`"
											:data="getUniqueValues(field)"
											input-class="search"
											@hit="filterAction('table')"
											@input="filterAction('table')"
											/> -->
											<v-select :options="getUniqueValues(field)" v-model="filters[field].value" :placeholder="``" @input="filterAction('table')" :multiple="false" class="m-0 p-0"></v-select>
										</template>
										<span v-else-if="field=='Date'">
											<date-range-picker
											ref="picker"
											:locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
											control-container-class="	form-control reportrange-text form-control-sm"
											:minDate="minDate"
											:maxDate="maxDate"
											:singleDatePicker="false"
											:timePicker="false"
											:showWeekNumbers="false"
											:showDropdowns="true"
											:autoApply="true"
											@update="updateDateFilterValues"
											v-model="filters[field].value"
											>
											<!-- <template v-slot:input="picker" style="min-width: 350px;">
												{{ picker.startDate }} - {{ picker.endDate }}
											</template> -->
										</date-range-picker>
										</span>
										<span v-else-if="field!='selected_gene'">
											<input class="form-control form-control-sm" :class="(field=='Cell_amount') ? 'filterWithComp' : '' " type="text" v-model="filters[field].value" @input="filterAction('table')">
										</span>
									</th>
								</tr>
							</thead>
							<tbody v-for="(row,ridx) in filteredData" :key="ridx">						
								<tr style="cursor: pointer" @click="showMore(row)">
									<td class="pointer nowrap">
										<span v-if="!download.status"><v-icon class="align-middle" name="plus-square" v-if="!row._show"></v-icon><v-icon class="align-middle" name="minus-square" v-if="row._show"></v-icon></span>
										<!-- <span v-else><input type="checkbox" v-model="row.selected"></span> -->
										<div v-else class="custom-control custom-checkbox" style="display:inline-flex">
											<input type="checkbox" class="custom-control-input" @click="selectRow('row',row)" :id="'customBatchCheckSelectRow'+ridx" v-model="row.selected">
											<label class="custom-control-label" :for="'customBatchCheckSelectRow'+ridx">&nbsp;</label>
										</div>
										<span class="badge badge-sm badge-info ml-1" v-if="row.h5ad">.h5ad</span>
									</td>
									<template v-for="(field,fidx) in fields.main" >
										<td class="main_td text-center" :key="fidx"> <scrnaseq-fields :row="row" :field='field' :annotation="annotation"></scrnaseq-fields></td>
									</template>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import XLSX from 'xlsx'
import moment from 'moment'
import { HTTP } from '@/router/http'
import { serverURL } from '@/app_config'
import { mapGetters } from 'vuex'
import loading from '@/components/globals/loading'
import scrnaseqFields from '@/components/Fields.vue'
import scrnaseqDetails from '@/components/Details.vue'
import geneDatasetHeatmap from '@/components/geneDatasetHeatmap'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('v-select', vSelect)
export default {
	name: 'ScRNAseq',
	components: {scrnaseqFields, scrnaseqDetails, geneDatasetHeatmap, loading, DateRangePicker },
	data () {
		return {
			displayOnlyData: true,
			dataset_id: null,
			raw_data: [],
			download:{status:false,all:false,cas:false},
			filteredData: [],
			categories: [],
			filters: {},
			ad_filters: { status: false, list: [], comparators: ['=', 'like', '<=', '>='] },
			choices: [],
			obj_keys: {},
			loading: { global: true, sync: false },
			upload: { status: false,
				options: { url: serverURL + 'scrnaseq', headers: HTTP.defaults.headers.common, paramName: 'file' }
			},
			latest_sync: null,
			structure: {},
			fields: { main: ['unique_id', 'Date', 'Title', 'Tissue', 'Cancer_type', 'Treatment_type', 'Enrichment_cell_types', 'Cell_amount'], medium: ['First_Author', 'Abstract'] },
			// search:{status:true,name:'SPDL1',cas:''}
			search: { status: false, name: '', cas: '', results: {} },
			cv_cell_types: ['B', 'DC', 'Endothelial', 'Fibroblast', 'Macrophage', 'Malignant', 'Mast', 'Monocyte', 'NK', 'Pericyte', 'Plasma', 'T CD4', 'T CD8', 'T reg', 'Unknown'],
			annotationTypes: ['CHETAH', 'major', 'minor', 'immune'],
			annotation: 'CHETAH',
			heatmapScaled: false,
			total_h5ad:{nb:0,size:0}
		}
	},
	computed: {
		...mapGetters({
			datasets: 'scrnaseq_datasets'
		}),
		formattedSearch () {
			return (this.search.cas === 'gene' && this.search.name) ? this.search.name.toUpperCase() : this.search.name
		},
		filteredDatasets () {
			return _.map(this.filteredData, d => d.unique_id)
		},
		selectedData(){
			let data = _.filter(this.filteredData,function(d){return d.selected;});
			return data;
		},
		selectedH5adSize(){
			let size;
			if (this.download.cas==='selected'){
				size = _.sumBy(this.selectedData,'h5ad_size');
			}
			else if(this.download.cas==='all'){
				size = this.total_h5ad.size;
			}
			return Math.floor(size/1000000);
		},
		minDate () {
			let d = new Date('2017-01-01')
			return d;
		},
		maxDate () {
			let d = new Date()
			return d;
		}
	},
	methods: {
		resetSearch () {
			this.$set(this.search, 'name', '')
			this.$set(this.search, 'status', false)
			this.$set(this.search, 'cas', '')
			this.resetFilters()
			this.filteredData = this.raw_data
		},
		listCellTypes () {
			const _this = this
			HTTP.get('/scrnaseq/celltypes?annotation=' + _this.annotation).then(res => {
				_this.cv_cell_types = res.data
			})
		},
		searchGeneOrCell (action, cas) {
			let _this = this
			if (!_this.search.status) {
				_this.$set(_this.search, 'name', '')
				_this.$set(_this.search, 'status', true)
				_this.$set(_this.search, 'cas', cas)
				_this.$nextTick(() => {
					if (_this.$refs[`${cas}Search`]) {
						_this.$refs[`${cas}Search`].focus()
					}
					if (_this.search.cas === 'cell') {
						this.listCellTypes()
					}
				})
			} else {
				if (action === 'search') {
					HTTP.get('/scrnaseq/' + _this.search.cas + '/' + _this.search.name + '?annotation=' + _this.annotation).then(res => {
						let validRes = {}
						let data = (res.data.rank !== undefined) ? res.data.rank : res.data
						_.forEach(data, (v, k) => {
							if (v !== -1) validRes[k] = v
						})
						if (_.keys(validRes).length) {
							_this.$snotify.success(_.keys(validRes).length + ' datasets containing the  ' + _this.search.cas + ' ' + _this.search.name)
							_this.search.results = validRes
							_this.obj_keys['search'] = (_this.search.cas === 'gene') ? { display: _this.search.name + ' entropy rank', id: 'search' } : { display: 'Nb ' + _this.search.name + ' cells', id: 'search' }
							if (_this.fields.main.indexOf('search') < 0) _this.fields.main.push('search')
							_this.filters['search'] = { id: 'search', value: null }
							_this.$set(_this.search, 'status', false)
							_this.filterAction(_this.search.cas)
						} else {
							_this.$snotify.warning('Sorry, there is no dataset containing the ' + _this.search.cas + ' ' + _this.search.name)
						}
					})
				} else {
					_this.$set(_this.search, 'status', false)
					_this.$set(_this.search, 'cas', null)
				}
			}
		},

		onComplete (file, status, xhr) {
			let _this = this
			if (status === 'success') {
				let res = JSON.parse(xhr.response)
				if (res.data) {
					let message = 'file uploaded successfully : ' + res.data.length + ' publications. '
					this.$snotify.success(message)
					_this.init(res)
				} else {
					this.$snotify.warning('file uploaded with warning')
				}
			} else {
				let jsonRes = JSON.parse(xhr.response)
				let message = (jsonRes.message) ? jsonRes.message : null
				this.$snotify.error('Sorry, an error occured: ' + message)
			}
			_this.$set(_this.upload, 'status', false)
		},

		uploadExcelFile () {
			let _this = this
			_this.$set(_this.upload, 'status', !_this.upload.status)
		},
		fdate: function (date) {
			return (date) ? date.split(' ')[0] : date
		},
		removeFilter (id, lidx) {
			let _this = this
			_this.$set(_this.filters[id], 'value', null)
			if (_this.ad_filters.list.length > 1) {
				_this.ad_filters.list.splice(lidx, 1)
			}
			if (id === 'Date'){
				_this.$set(_this.filters[id], 'value', {startDate: null,endDate: null})
			}
			_this.filterAction()
		},
		addFilter () {
			let _this = this
			let nextFilters = _.filter(_this.filters, function (f) {
				return _this.fields.main.indexOf(f.id) < 0 && !f.value
			})
			let nextFid = (nextFilters[0]) ? nextFilters[0].id : null
			_this.ad_filters.list.push({ id: nextFid, value: null, comp: '=' })
		},
		advancedFilters () {
			let _this = this
			_this.$set(_this.download, 'status', false)
			_this.$set(_this.ad_filters, 'status', !_this.ad_filters.status)
			_this.resetFilters()
		},
		synchronize () {
			let _this = this
			_this.$set(_this.loading, 'sync', true)
			HTTP.get('/synchronize_scrnaseq').then(res => {
				_this.$set(_this.loading, 'sync', false)
				this.init(res.data)
			})
		},
		showMore (row) {
			if(!this.download.status){				
				this.study = row
				this.$router.push('/' + row.unique_id)
			}
		},
		resetFilters (init) {
			let _this = this
			_this.filters = {}
			if (!init) {
				_this.ad_filters.list = []
				_this.addFilter()
			}
			_.forEach(_this.obj_keys, function (k, field) {
				let value = (field === 'Date') ? {startDate: null, endDate: null} : null
				_this.filters[field] = { id: field, value: value }
			})
			if (_this.filters.Cell_amount !== undefined) _this.filters.Cell_amount.comp = '>='

			let selectedGeneIdx = _this.fields.main.indexOf('search')
			if (selectedGeneIdx > -1) _this.fields.main.splice(selectedGeneIdx, 1)

			if (_this.obj_keys['search']) delete _this.obj_keys['search']
			_this.filterAction()
		},
		updateDateFilterValues (dateFilters){
			this.$set(this.filters.Date.value,'startDate',dateFilters.startDate)
			this.$set(this.filters.Date.value,'endDate',dateFilters.endDate)
			this.filterAction('table')
		},
		filterAction (from) {
			let _this = this
			if (_this.timeoutFilterScrnaseq) { clearTimeout(_this.timeoutFilterScrnaseq) }
			_this.timeoutFilterScrnaseq = setTimeout(() => {
				_this.filteredData = []
				_.forEach(_this.raw_data, function (d) {
					let pass = true
					if (_this.displayOnlyData && !d.h5ad) pass = false
					if (from === 'gene') {
						let geneKeys = []
						_.forEach(_this.search.results, (r, Idx) => {
							if (r[`annotation_${_this.annotation}`] !== undefined) geneKeys.push(Idx)
						})
						if (geneKeys.indexOf(d.unique_id) > -1) {
							d['search'] = _this.search.results[d.unique_id]
						} else {
							d['search'] = null
							pass = false
						}
					} else if (from === 'cell') {
						if (Object.keys(_this.search.results).indexOf(d.unique_id) > -1) {
							d['search'] = _this.search.results[d.unique_id]
						} else {
							d['search'] = null
							pass = false
						}
					} else if (from === 'table') {
						_.forEach(_this.filters, function (f,field) {
							if (field === 'Date'){
								let datasetDate = new Date(d[f.id])
								if (f.value.startDate && f.value.endDate && (datasetDate < f.value.startDate || datasetDate > f.value.endDate)) pass = false
							}
							else if (f.value) {
								if ((!f.comp || f.comp === 'like') && (!d[f.id] || d[f.id].toLowerCase().indexOf(f.value.toLowerCase()) < 0)) pass = false
								else if (f.comp === '=' && (!d[f.id] || d[f.id].toLowerCase() !== f.value.toLowerCase())) pass = false
								else if (f.comp === '<=' && (!d[f.id] || +d[f.id] > +f.value)) pass = false
								else if (f.comp === '>=' && (!d[f.id] || +d[f.id] < +f.value)) pass = false									
							}
						})
					} else {
						_.forEach(_this.ad_filters.list, function (f) {
							if (f.id && _this.filters[f.id].value) {
								if (!d[f.id]) pass = false
								else if (f.comp === 'like' && d[f.id].toLowerCase().indexOf(_this.filters[f.id].value.toLowerCase()) < 0) pass = false
								else if (f.comp === '=' && d[f.id].toLowerCase() !== _this.filters[f.id].value.toLowerCase()) pass = false
								else if (f.comp === '<=' && +d[f.id] > _this.filters[f.id].value) pass = false
								else if (f.comp === '>=' && +d[f.id] < _this.filters[f.id].value) pass = false
							}
						})
					}
					if (pass) {
						_this.filteredData.push(d)
					}
				})
				if (from === 'gene') {
					_this.filteredData = _.sortBy(_this.filteredData, function (d) { return d.search[`annotation_${_this.annotation}`] })
				} else if (from === 'cell') {
					_this.filteredData = _.sortBy(_this.filteredData, 'search')
					_.reverse(_this.filteredData)
				}
			}, 300)
		},
		init () {
			let _this = this
			let data = _this.datasets
			
			_this.latest_sync = data.latest_sync
			_this.raw_data = data.data
			_.forEach(_this.raw_data,function(d){
				if(d.h5ad){
					_this.$set(_this.total_h5ad,'nb',_this.total_h5ad.nb+1);
					_this.$set(_this.total_h5ad,'size',_this.total_h5ad.size+ d.h5ad_size);
				}
			})
			_this.structure = data.structure
			_this.categories = data.categories
			_this.$set(_this.upload, 'status', false)

			_.forEach(data.choices, function (c, cidx) {
				if (cidx === 'Date') c = _.map(c, function (d) { return _this.fdate(d) })
				c.unshift(null)
				_this.obj_keys[cidx] = { id: cidx, display: cidx.replace(/_/g, ' ') }
				_this.choices[cidx] = c
			})

			_this.resetFilters(true)
			_this.filteredData = _this.raw_data
			_this.fields.other = _.map(_.filter(_this.obj_keys, function (k, kidx) { return _this.fields.main.indexOf(kidx) < 0 && _this.fields.medium.indexOf(kidx) < 0 }), 'id')
			_this.$set(_this.loading, 'global', false)
		},

		isTypeahead (field) {
			return ['Tissue', 'Cancer_localization', 'Cancer_type', 'Treatment_type', 'Enrichment_cell_types'].indexOf(field) > -1
		},
		getUniqueValues (field) {
			return _.filter(_.uniq(_.map(this.filteredData, d => d[field]))).sort()
		},
		downloadAction(action,cas){
			let _this = this;
			if (action==='init'){
				this.$set(this.download,'status',!this.download.status);
			}
			else if (action==='cancel'){
				_this.resetDownload();
				_this.$refs.modalConfirmDownload.hide();
			}
			else if (action==='download'){
				this.$set(this.download,'cas',cas);
				_this.$refs.modalConfirmDownload.show();
			}
			else{
				let params = {cas:_this.download.cas};
				if (params.cas!=='all'){
					let selection_ids = (cas==='filtered') ? _.map(this.filteredData,'unique_id') : _.map(this.selectedData,'unique_id'); 
					params.ids = selection_ids.join(',');
				}
				this.$set(this.download,'loading',true);	

				_this.$refs.modalConfirmDownload.hide();
				HTTP.get('scrnaseq/download?cas='+params.cas+'&ids='+params.ids).then(res => {
					_this.resetDownload();
					self.location.href = serverURL+"/scrnaseq/download/"+res.data;
				}).catch(function (err) {
					_this.$snotify.error("Error during download");
					_this.resetDownload();
				})
			}
		},
		resetDownload(){
			let _this = this;
			_this.$set(_this.download,'loading',false);	
			_this.$set(_this.download,'status',!_this.download.status);
			_.forEach(_this.filteredData,function(d){ _this.$set(d,'selected',false); })
			_this.$set(_this.download,'all',false);
		},
		selectRow(cas,row){
			let _this = this;
			setTimeout(function(){
				if (cas==='global'){
					_.forEach(_this.filteredData,function(d){ _this.$set(d,'selected',_this.download.all); })
				}
				else{
					let tmp = _.countBy(_this.filteredData,'selected');
					let keys = _.keys(tmp);
					if (keys.length ===1 && keys[0]==='true'){_this.$set(_this.download,'all',true);}
					else _this.$set(_this.download,'all',false);
				}
			},100)
			
		},
		downloadScRNAseqDb () {
			const data = XLSX.utils.json_to_sheet(this.raw_data)
			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, data, 'scRNAseqDB')
			const xlsxfilename = 'IMMUcan_scRNAseqDB_' + moment().format('YYYY-MM-DD') + '.xlsx'
			XLSX.writeFile(wb, xlsxfilename)
		}
	},
	mounted () {
		let _this = this;
		setTimeout(function(){
			
			_this.$store.dispatch('getScrnaseqDatasets').then(res => {
				_this.init()
				if (_this.$route.params.dataset_id) {
					_this.dataset_id = _this.$route.params.dataset_id
				}
			})
		},300)
	},
	destroyed () {
		let _this = this
		clearTimeout(_this.timeoutFilterScrnaseq)
	},
	watch: {
		annotation (n, o) {
			if (n && n !== o) {
				if (this.search.cas === 'cell') {
					this.listCellTypes()
				} else {
					this.filterAction('gene')
				}
			}
		},
		heatmapScaled (n, o) {
			if (n !== o && this.search.cas === 'gene') {
				this.filterAction('gene')
			}
		},
		displayOnlyData (n, o) {
			if (n !== o) {
				this.filterAction()
			}
		},
		'$route' (to, from) {
			let _this = this
			if (to.params.dataset_id) _this.dataset_id = to.params.dataset_id
			else _this.dataset_id = null
		}
	}

}

</script>
<style scoped>
	.filterWithComp{
		display:inline-flex;
		width:45%;
	}
	div.dz-message {
		padding: 20px;
		margin: 20px auto;
		width: 750px;
		border: 1px dashed red;
	}
	div.dz-message h2{
		font-size: 16px;
		text-align: center;
	}

	.loading_class{
		opacity:0.5;
		cursor:wait;
	}
	.main_td{
		min-width:120px;
	}

	.inline{
		display:inline-block;
	}
	.card-columns {
		column-count: 3;
	}
	.justify{
		text-align:justify;
	}
	.scrnaseq_container{
		padding: 0 2%;
	}
	.ddright{
		text-align:right;
	}
	td.nowrap {
		white-space: nowrap;
	}
</style>
