<template>
<div class="test container">
	<div :id="`venn${idTitle}`" style="width: 90%; height: 100px" v-b-popover.hover.top="popover"></div>
</div>
</template>

<script>
import * as venn from 'venn.js'
import * as d3 from 'd3'
export default {
	name: 'vennChart',
	props: ['data', 'title'],
	data () {
		return {
		}
	},
	computed: {
		idTitle () {
			return this.title.replace(/\s/g, '_')
		},
		popover () {
			let texts = []
			let doubleCounts = (this.data.length > 2) ? this.data[2].size : 0
			_.forEach(this.data, (s, idx) => {
				let size = (idx < 2) ? s.size - doubleCounts : s.size
				texts.push(s.sets.join(' + ') + ': ' + size)
			})
			return {
				html: true,
				title: this.data[0].sets[0] + ' vs ' + this.data[1].sets[0],
				content: texts.join('<br>')
			}
		}
	},
	mounted () {
		var chart = venn.VennDiagram().width(200).height(100)
		d3.select('#venn' + this.idTitle).datum(this.data).call(chart)
	}
}
</script>
<style>
#venn{
	height: 100px;
	width: 100px;
	border: 1px solid red;
}
</style>
