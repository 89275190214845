<template>
	<div class="geneViolin" style='position: relative;'>
		<button type="button"  v-if="!loading" class="btn btn-sm btn-link float-right hideDownloads" @click="download" style="position: absolute; top: 10px; right: 10px;"  v-b-tooltip.hover title="download plots as PNG"><v-icon name="download" /></button>
		<div v-if="!loading" ref="geneViolin" >
			<div id="geneDist"></div>
			<div id="piecharts">
				<div class="pieContainer" v-for="cat in xData" :key="cat" :style="`width: ${100/xData.length}%; float: left; text-align: center`">
					<div style="width: 55px; margin: auto"><pie-chart :data="computePie(cat)" :cell-type="cat" :color="colors[cat]" size='55'></pie-chart></div>
					<p><small>{{computePie(cat).total}} cells</small></p>
				</div>
			</div>
			<div class="form-group form-check float-right hideDownloads">
				<input type="checkbox" class="form-check-input" id="filter" v-model="filterZero">
				<label class="form-check-label" for="filter">Filter out zero values</label>
			</div>
		</div>
		<p class = "text-center mt-3" v-else><pulse-loader :loading="loading" color="#18BC9C"></pulse-loader></p>
	</div>
</template>

<script>
import * as d3 from 'd3'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import pieChart from '@/components/pieChart'
import palette from 'google-palette'
import html2canvas from 'html2canvas'
export default {
	name: 'geneViolin',
	props: ['gene', 'annotation', 'dataset', 'load', 'ready'],
	components: { PulseLoader, pieChart },
	data () {
		return {
			loading: true,
			colors: {},
			filterZero: false,
			xData: []
		}
	},
	computed: {
		...mapGetters({
			gene_dist_data: 'scrnaseq_gene_dist'
		}),
		annotationName () {
			return this.annotation.indexOf('annotation') === -1 ? `annotation_${this.annotation}` : this.annotation
		}
	},
	methods: {
		computePie (cellType) {
			const _this = this
			let total = _.filter(_this.gene_dist_data.data, d => d[_this.annotationName] === cellType)
			let noZero = _.filter(total, d => +d.value)
			return { total: total.length, noZero: noZero.length, zero: total.length - noZero.length }
		},
		drawGeneDist (gene) {
			const _this = this
			let data = _.filter(_.values(_this.gene_dist_data.data), d => !_this.filterZero || +d.value)
			this.xData = _.uniq(_.map(data, _this.annotationName))
			let colorPalette = palette('mpn65', this.xData.length).map(c => `#${c}`)
			let cat = this.xData
			cat.sort()
			this.colors = {}
			_.forEach(cat, (c, cidx) => {
				_this.colors[c] = colorPalette[cidx]
			})

			let values = _.map(data, 'value')
			let yRange = [_.min(values), _.max(values)]

			// let width = document.getElementById('geneDist').getBoundingClientRect().width
			// https://www.d3-graph-gallery.com/graph/violin_basicHist.html
			var margin = { top: 50, right: 30, bottom: 30, left: 40 }
			if (this.xData.length > 8) { margin.bottom = 100 }
			var width = document.getElementById('geneDist').getBoundingClientRect().width - margin.left - margin.right
			var height = window.innerHeight / 3 - margin.top - margin.bottom

			// append the svg object to the body of the page
			let existingSvg = d3.select('#geneDist').select('svg')
			if (existingSvg)	existingSvg.remove()
			var svg = d3.select('#geneDist').append('svg')
				.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom)
				.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

			// svg.append('text').text(_this.gene_dist_data.gene + ' (grouped by ' + _this.gene_dist_group.replace('_', ' ') + ')').attr('x', width * 0.4).attr('y', -20)

			// Build and Show the Y scale
			var y = d3.scaleLinear().domain(yRange).range([height, 0])
			svg.append('g').call(d3.axisLeft(y))

			// Build and Show the X scale. It is a band scale like for a boxplot: each group has an dedicated RANGE on the axis. This range has a length of x.bandwidth
			var x = d3.scaleBand().range([ 0, width ]).domain(this.xData).padding(0.05)
			svg.append('g').attr('class', 'xAxisViolin').attr('transform', 'translate(0,' + height + ')').call(d3.axisBottom(x))
			if (this.xData.length > 8) {
				d3.select('.xAxisViolin').selectAll('text').attr('y', 10).attr('x', 9).attr('dy', '.35em').attr('transform', 'rotate(45)').style('text-anchor', 'start')
			}


			// Features of the histogram
			var histogram = d3.histogram().domain(y.domain()).thresholds(y.ticks(10)).value(d => d)
			// Important: how many bins approx are going to be made? It is the 'resolution' of the violin plot

			// Compute the binning for each group of the dataset
			var sumstat = d3.nest()
				.key(function (d) { return d[_this.annotationName] })
				.rollup(function (d) {
					let input = d.map(function (g) { return g.value })
					let bins = histogram(input)
					return (bins)
				})
				.entries(data)

			var boxplotstat = d3.nest()
				.key(function (d) { return d[_this.annotationName] })
				.rollup(function (d) {
					let input = d.map(function (g) { return g.value })
					let dataSorted = _.filter(input.sort(d3.ascending), g => g >= 0)
					if (!dataSorted.length) {
						return {
							q1: 0,
							q3: 0,
							median: 0,
							min: 0,
							max: 0
						}
					}
					let q1 = d3.quantile(dataSorted, 0.25)

					let median = d3.quantile(dataSorted, 0.5)
					let q3 = d3.quantile(dataSorted, 0.75)
					let interQuantileRange = q3 - q1
					let min = q1 - 1.5 * interQuantileRange
					let max = q1 + 1.5 * interQuantileRange
					return {
						q1: q1,
						q3: q3,
						median: median,
						min: min,
						max: max
					}
				})
				.entries(data)


			// What is the biggest number of value in a bin? We need it cause this value will have a width of 100% of the bandwidth.
			var maxNum = 120
			var xNum = d3.scaleLinear().range([0, x.bandwidth()]).domain([-maxNum, maxNum])

			// Add the shape to this svg!
			svg.selectAll('myViolin').data(sumstat).enter()
				.append('g').attr('transform', function (d) { return ('translate(' + x(d.key) + ' ,0)') })
				.append('path').datum(function (d) {
					let ret = []
					let total = 0
					_.forEach(d.value, a => {
						total += a.length
					})
					_.forEach(d.value, a => {
						ret.push({
							key: d.key,
							total: total,
							length: a.length,
							x0: a.x0,
							x1: a.x1
						})
					})
					return ret
				})
				.style('stroke', 'none')
				.style('fill', function (d, idx) {
					return _this.colors[d[0].key]
				})
				.attr('d', d3.area()
					.x0(function (d) { return (xNum(-d.length / d.total * 100)) })
					.x1(function (d) { return (xNum(d.length / d.total * 100)) })
					.y(function (d) { return (y(d.x0)) })
					.curve(d3.curveCatmullRom) // This makes the line smoother to give the violin appearance. Try d3.curveStep to see the difference
				)
			svg.selectAll('myViolin').data(boxplotstat).enter()
				.append('g').attr('transform', function (d) { return ('translate(' + x(d.key) + ' ,0)') })
				.append('line').datum(function (d) { return d.value })
				.attr('x1', xNum(maxNum) / 2)
				.attr('x2', xNum(maxNum) / 2)
				.attr('y1', function (d) { return y(d.min) })
				.attr('y2', function (d) { return y(d.max) })
				.attr('stroke', 'black')


			let boxwidth = 20
			svg.selectAll('myViolin').data(boxplotstat).enter()
				.append('g').attr('transform', function (d) { return ('translate(' + x(d.key) + ' ,0)') })
				.append('rect').datum(function (d) { return d.value })
				.attr('x', xNum(maxNum) / 2 - boxwidth / 2)
				.attr('y', function (d) { return y(d.q3) })
				.attr('height', function (d) { return (y(d.q1) - y(d.q3)) })
				.attr('width', boxwidth)
				.attr('stroke', 'black')
				.style('fill', 'rgba(180,180,180,0.4)')

			svg.selectAll('myViolin').data(boxplotstat).enter()
				.append('g').attr('transform', function (d) { return ('translate(' + x(d.key) + ' ,0)') })
				.append('line').datum(function (d) { return d.value })
				.attr('x1', xNum(maxNum) / 2 - boxwidth / 3)
				.attr('x2', xNum(maxNum) / 2 + boxwidth / 3)
				.attr('y1', function (d) { return (y(d.max)) })
				.attr('y2', function (d) { return (y(d.max)) })
				.attr('stroke', 'black')

			svg.selectAll('myViolin').data(boxplotstat).enter()
				.append('g').attr('transform', function (d) { return ('translate(' + x(d.key) + ' ,0)') })
				.append('line').datum(function (d) { return d.value })
				.attr('x1', xNum(maxNum) / 2 - boxwidth / 3)
				.attr('x2', xNum(maxNum) / 2 + boxwidth / 3)
				.attr('y1', function (d) { return (y(d.min)) })
				.attr('y2', function (d) { return (y(d.min)) })
				.attr('stroke', 'black')

			svg.selectAll('myViolin').data(boxplotstat).enter()
				.append('g').attr('transform', function (d) { return ('translate(' + x(d.key) + ' ,0)') })
				.append('line').datum(function (d) { return d.value })
				.attr('x1', xNum(maxNum) / 2 - boxwidth / 2)
				.attr('x2', xNum(maxNum) / 2 + boxwidth / 2)
				.attr('y1', function (d) { return (y(d.median)) })
				.attr('y2', function (d) { return (y(d.median)) })
				.attr('stroke', 'black')
		},
		download () {
			const _this = this
			_.forEach(document.getElementsByClassName('hideDownloads'), e => {
				e.style.display = 'none'
			})
			html2canvas(this.$refs.geneViolin, { allowTaint: true, scrollX: 0, scrollY: -window.scrollY }).then(function (canvas) {
				var link = document.createElement('a')

				if (typeof link.download === 'string') {
					link.href = canvas.toDataURL()
					link.download = _this.gene + '_violin_plots.png'

					// Firefox requires the link to be in the body
					document.body.appendChild(link)

					// simulate click
					link.click()

					// remove the link when done
					document.body.removeChild(link)
				} else {
					window.open()
					// window.open(uri)
  			}
				_.forEach(document.getElementsByClassName('hideDownloads'), e => {
					e.style.display = 'inline-block'
				})
			})
		}


	},
	mounted () {
		const _this = this
		_this.loading = true
		let params = { gene: this.gene }
		params.dataset = (this.dataset !== undefined) ? this.dataset : this.$route.params.dataset_id
		_this.$store.dispatch('getScrnaseqGeneDistribution', params).then(res => {
			if (res !== 'LOADING') {
				_this.loading = false
				Vue.nextTick().then(() => _this.drawGeneDist(_this.gene))
			}
		})
	},
	watch: {
		ready (n, o) {
			const _this = this
			if (n && !o) {
				_this.loading = false
				Vue.nextTick().then(() => _this.drawGeneDist(_this.gene))
			} else if (o && !n) {
				_this.loading = true
			}
		},
		filterZero () {
			this.drawGeneDist(this.gene)
		},
		annotation (n, o) {
			if (n && n !== o) {
				this.drawGeneDist(this.gene)
			}
		}
	}
}
</script>
<style scoped>
#piecharts {
	height: 100px;
	width: auto;
	margin-left: 40px;
	margin-right: 30px;
}
.pieContainer {
	height: 100%;
}
</style>
