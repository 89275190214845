<template>
	<div class = 'container-fluid' id="heatmapChart" style="position:relative">
		<div v-if="loading" style="position: absolute; top: 200px; text-align: center; width: 100%; margin: auto;"><loading></loading></div>
		<h5 class="text-center text-warning" v-if="!loading && scaled">Results should be interpreted with care because some studies are enriched for certain cell types which skews the values</h5>
		<chart :options="chartOptionsHeatmap" style="height: 1000px; width:75%;margin-left: 12% " :style="`visibility:${loading?'hidden':'visible'}`" @click="onClick" :autoresize="true" ref="heatmapChart" ></chart>
		<b-modal id="geneDistModalHeatmap" :title="`${gene} expression distribution in ${violinDataset} with ${annotation} annotation`" size="xl" :hide-footer="true">
			<gene-violin :gene='gene' :group='annotation' :annotation='annotation' :dataset='violinDataset' :load="true" :ready="geneDistStatus"></gene-violin>
		</b-modal>

	</div>

</template>

<script>
import Vue from 'vue'
import { HTTP } from '@/router/http'
import geneViolin from '@/components/geneViolin'
import Worker from 'worker-loader!@/workers/hclust.js'
import loading from '@/components/globals/loading'
export default {
	name: 'geneDatasetHeatmap',
	props: ['gene', 'annotation', 'filteredDatasets', 'scaled'],
	components: { geneViolin, loading },
	data () {
		return {
			datasets: [],
			cellTypes: [],
			data: [],
			loading: false,
			geneDistStatus: false,
			violinDataset: '',

			chartOptionsHeatmap: {
				tooltip: {
					position: 'top'
				},
				dimensions: [],
				animation: false,
				grid: {
					height: '70%',
					top: '0',
					left: '300px'
				},
				xAxis: {
					type: 'category',
					data: [],
					axisLabel: {
						rotate: '90',
						fontSize: 12
					},
					splitArea: {
						show: true
					}
				},
				yAxis: {
					type: 'category',
					data: [],
					inverse: true,
					triggerEvent: true,
					splitArea: {
						show: true
					}
				},
				visualMap: {
					min: 0,
					max: 10,
					calculable: true,
					show: true,
					orient: 'vertical',
					right: '0',
					top: '0%',
					inRange: {
						color: ['#000004', '#1B0C42', '#4B0C6B', '#781C6D', '#A52C60', '#CF4446', '#ED6925', '#FB9A06', '#F7D03C', '#FCFFA4']
					}
				},
				series: [{
					name: 'Datasets',
					type: 'heatmap',
					data: [],
					label: {
						show: false
					},
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}]
			}
		}
	},
	methods: {

		onClick (evt) {
			if (evt.componentType === 'yAxis' && evt.value) {
				this.$router.push('/' + evt.value)
			} else if (evt.componentType === 'series' && evt.value) {
				this.violinDataset = this.chartOptionsHeatmap.yAxis.data[evt.value[1]]
				this.$bvModal.show('geneDistModalHeatmap')
			}
		},
		computeHeatmap () {
			const _this = this
			this.loading = true
			HTTP.get(`/scrnaseq/gene/${this.gene}/heatmap?annotation=${this.annotation}&scaled=${this.scaled}`).then(res => {
				if (!res.data.length) {
					return
				}
				let max = 1
				let filteredData = [res.data[0]]
				_.forEach(_this.filteredDatasets, (datasetName) => {
					_.forEach(res.data, row => {
						let cols = row.split(',')
						if (cols[0] === datasetName) {
							filteredData.push(row)
							_.forEach(cols, c => {
								if (!isNaN(c) && max < +c) max = +c
							})
						}
					})
				})
				
				// // clean data. Remove empty columns or columns with only one value
				let data2d = _.map(filteredData, d => d.split(","))
				let t_data2d = data2d[0].map((col, i) => data2d.map(row => row[i]))
				let cleandata = [], t_cleandata = [], trow= [], ok = -1, j, i
				for ( j = 2; j < t_data2d.length; j++ ){
					trow = t_data2d[j];
					ok = -1;
					for (i = 1; i < trow.length; i++){
						if (+trow[i] > 0) ok++;
					}
					if (ok > 0){
						t_cleandata.push(trow);
					}
				}
				t_cleandata.unshift(t_data2d[1]);
				t_cleandata.unshift(t_data2d[0]);
				cleandata = t_cleandata[0].map((col, i) => t_cleandata.map(row => row[i]))
				cleandata = cleandata.map(d => d.join(","))

				_this.chartOptionsHeatmap.visualMap.max = max
				const worker = new Worker()
				worker.postMessage({ results: cleandata })
				worker.onmessage = function (event) {
					document.getElementById('heatmapChart').style.height = ((event.data.heatmap.datasets.length * 25) + 100) + 'px'
					Vue.nextTick(() => {
						_this.chartOptionsHeatmap.grid.height = ((event.data.heatmap.datasets.length * 25) + 'px')
						_this.chartOptionsHeatmap.xAxis.data = event.data.heatmap.cellTypes
						_this.chartOptionsHeatmap.yAxis.data = event.data.heatmap.datasets
						_this.$refs.heatmapChart.$el.style.height = '100%'
						if (event.data.heatmap.matrix !== undefined) {
							_this.chartOptionsHeatmap.series[0].data = event.data.heatmap.matrix
							_this.loading = false
						}
					})
				}
			}).catch(err => this.$snotify.error(err))
		}
	},
	watch: {
		annotation (n, o) {
			if (n && n !== o) {
				this.computeHeatmp = _.debounce(this.computeHeatmap, 300)
				this.computeHeatmap()
			}
		},
		scaled (n, o) {
			if (n !== o) {
				this.chartOptionsHeatmap.visualMap.min = (n) ? -2 : 0
				this.chartOptionsHeatmap.visualMap.max = (n) ? 6 : 10
				this.chartOptionsHeatmap.visualMap.inRange.color = (n) ? ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026'] : ['#000004', '#1B0C42', '#4B0C6B', '#781C6D', '#A52C60', '#CF4446', '#ED6925', '#FB9A06', '#F7D03C', '#FCFFA4']
				this.computeHeatmp = _.debounce(this.computeHeatmap, 300)
				this.computeHeatmap()
			}
		},
		filteredDatasets (n, o) {
			this.computeHeatmp = _.debounce(this.computeHeatmap, 300)
			this.computeHeatmap()
		}
	},
	mounted () {
		this.computeHeatmp = _.debounce(this.computeHeatmap, 300)
		this.computeHeatmap()
	}
}
</script>
