<template>
<div class = 'pieChart' @click="showValues=!showValues">
	<div v-show="showValues" class = 'values'>
		<b>total:</b> {{data.total}} <br>
		<b>non-zero:</b> {{data.noZero}}
	</div>
	<div v-show="!showValues">
		<div>
			<chart :options="chartOptions" :style="`height: ${size}px; width: ${size}px`"></chart>
		</div>
	</div>
</div>
</template>

<script>
// import VueC3 from 'vue-c3'
export default {
	name: 'pieChart',
	props: ['data', 'cellType', 'size', 'color'],
	data () {
		return {
			showValues: false,
			chartOptions: {
				animation: false,
				tooltip: {
					trigger: 'item',
					formatter: '{a} <br/>{b} : {c}%'
				},
				series: [
					{
						name: this.cellType,
						type: 'pie',
						labelLine: {
							show: false
						},
						label: {
							show: false,
							position: 'center'
						},
						hoverOffset: 2,
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 2,
								shadowOffsetX: 1,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			}
		}
	},
	computed: {
		frequency () {
			return Math.round(parseInt(this.data.noZero) / (parseInt(this.data.total)) * 1000) / 10
		}
	},
	methods: {
	},
	mounted () {
		this.chartOptions.series[0].data.push({ value: Math.round(this.frequency * 10) / 10, name: 'non-zero', itemStyle: { color: this.color } })
		this.chartOptions.series[0].data.push({ value: Math.round((100 - this.frequency) * 10) / 10, name: 'zero', itemStyle: { color: this.color, opacity: 0.3 } })
	}
}
</script>

<style scoped>
svg {
	display: block;
	margin: auto;
}
div.variant_frequency{
	position: relative;
	display: inline-block;
}
div.legend{
	position: absolute;
	top: 5px;
	left: 0;
	line-height: 30px;
	font-size: 13px;
	font-weight: bold;
	width: 40px;
	text-align: center;
}
div.values {
	font-size: 11px;
}
</style>
