<template>
	<div class="patientPage" ref="patientPage" v-if="show" style="position: relative">
		<button type="button" class="btn btn-sm btn-link float-right hideDownloads" @click="download"  v-b-tooltip.hover title="download plots as PNG" ><v-icon name="download" /></button>
		<h4 class="form-inline">
			{{`${annotation.replace('_',' ')} distribution `}} <span v-if="categories.field.label && categoryList.length  && categoryList[0]" class="ml-1"> by  </span>
			<select class="form-control mx-2" v-model="categories.field.label" v-if="categoryList.length > 1 && categoryList[0]" @change="formatPlotData">
				<template v-for="cat in categoryList" >
					<option :value="cat"  :key="`${cat}`">{{cat.replace("_"," ")}}</option>
				</template>
			</select>
			<span class="ml-1" v-if="categories.field.label && categoryList[0] && categoryList.length === 1">{{categoryList[0]}}</span>
		</h4>
		<div class="form-check hideDownloads"  v-if='categories.sample.label'>
			<input class="form-check-input" type="checkbox" v-model="mergeSamples" id="mergeSampleCheckbox">
			<label class="form-check-label" for="mergeSampleCheckbox">
				Merge {{categories.sample.label+"s"}}
			</label>
		</div>
		<div class="plotContainer" :style="`height: ${patientBarPlotHeight}px;`" v-if="!patientPlotWarning">
			<div class="row">
				<template v-if="!singlePlot">
					<div :class="colClassName" v-for="cat0 in categories.field.values" :key="`${cat0}`">
						<h1 class="h5 text-center">{{cat0}}</h1>
						<chart :options="chartOptions[cat0]" :style="`width: 100%; height: ${patientBarPlotHeight}px`" :autoresize="true"></chart>
					</div>
				</template>
				<template v-else>
					<chart :options="chartOptions['sample']" :style="`width: 100%; height: ${patientBarPlotHeight}px`" :autoresize="true"></chart>
				</template>
			</div>
		</div>
		<p class="text-center text-muted mt-3" v-if="patientPlotWarning">{{patientPlotWarning}}</p>
	</div>
	<h5 class="text-center text-muted mt-3 pt-3" v-else>No patient data available</h5>
</template>

<script>

import Vue from 'vue'
import { mapGetters } from 'vuex'
import palette from 'google-palette'
import html2canvas from 'html2canvas'

var limitNbPatientPlots = 10
export default {
	name: 'patientPage',
	props: ['datasetId', 'annotation'],
	computed: {
		...mapGetters({
			datasets: 'scrnaseq_datasets',
			dataset_genes: 'dataset_genes',
			parameters: 'scrnaseq_parameters'
		}),
		categoryList () {
			let cats = []
			_.forEach(this.parameters.metadata, m => {
				m = m.toLowerCase()
				if (m !== 'orig.ident' && m !== 'patient' && m !== 'sample') cats.push(m)
			})
			if (!cats.length) {
				cats.push('')
			// 	_.forEach(this.parameters.metadata, m => {
			// 		if (m !== 'orig.ident' && m !== 'patient') cats.push(m)
			// 	})
			// }
			// if (!cats.length) {
			// 	_.forEach(this.parameters.metadata, m => {
			// 		if (m !== 'orig.ident') cats.push(m)
			// 	})
			}
			return cats
		},
		patientBarPlotHeight () {
			return Math.ceil(window.height / 3)
		},
		singlePlot () {
			return !(this.categories.field.values.length > 1)
		}

	},
	data () {
		return {
			dataset: {},
			colClassName: 'col-12',
			plotData: [],
			show: true,
			mergeSamples: false,
			chartOptions: {},
			categories: {
				sample: {
					label: '',
					values: []
				},
				field: {
					label: '',
					values: []
				}
			},
			colors: {},
			patientPlotWarning: ''
		}
	},
	methods: {
		getPlotColName (nb) {
			if (nb === 1) {
				this.colClassName = 'col-12'
			} else if (!(nb % 3)) {
				this.colClassName = 'col-4'
			} else if (!(nb % 4)) {
				this.colClassName = 'col-3'
			} else if (!(nb % 2)) {
				this.colClassName = 'col-6'
			} else {
				this.colClassName = 'col-12'
			}
		},
		getData () {
			const _this = this
			_this.chartOptions = {}
			this.$store.dispatch('getPatientBarData', { dataset_id: _this.datasetId, annotation: _this.annotation }).then(data => {
				if (_this.parameters.metadata !== undefined && _this.categoryList.length > 0) {
					_this.categories.field.label = _this.categoryList[0] + ''
				}
				_this.plotData = data
				_this.formatPlotData()
			})
		},
		formatPlotData () {
			const _this = this
			_this.show = true
			const headers = _.map(this.plotData[0], h => h.toLowerCase())
			let sampleIdx = headers.indexOf('sample')
			if (sampleIdx === -1) {
				sampleIdx = headers.indexOf('patient')
			}
			let cat0idx = headers.indexOf(this.categories.field.label.toLowerCase())
			if (sampleIdx === cat0idx) cat0idx = -1
			if (sampleIdx > -1) {
				this.categories['sample'].label = headers[sampleIdx]
			}
			if (cat0idx === -1 && sampleIdx > -1) {
				this.categories.field.label = headers[sampleIdx]
			}
			else if (cat0idx === -1 && sampleIdx === -1){
				this.categories.field.label = 'sample';
			}
			let annotIdx = headers.indexOf(this.annotation.toLowerCase())
			_this.categories.field.values = []
			_this.categories['sample'].values = []
			let cellTypes = []
			_.forEach(this.plotData, (cols, pIdx) => {
				if (pIdx) { // ignore header
					if (cols[annotIdx])	cellTypes.push(cols[annotIdx])
					if (cat0idx === -1 && sampleIdx > -1) {
						_this.categories.field.values.push(_this.categories['sample'].label)
					} else if (cat0idx > -1) {
						if (cols[cat0idx])	_this.categories.field.values.push(cols[cat0idx])
					}
					if (cols[sampleIdx])	_this.categories['sample'].values.push(cols[sampleIdx])
					else if (cat0idx > -1 && cols[cat0idx]){
						_this.categories['sample'].label="sample"
						_this.categories['sample'].values.push(cols[cat0idx])
					}
						
				}
			})
			if (sampleIdx === -1 && cat0idx === -1) {
				this.categories.field.values = ['sample'];
				this.categories.sample.values.push('sample');
				this.categories.sample.label = 'sample'
			}
			cellTypes = _.uniq(cellTypes)
			_this.categories['sample'].values = _.uniq(_this.categories['sample'].values)
			let colorPalette = palette('mpn65', cellTypes.length).map(c => `#${c}`)
			let cat = cellTypes
			cat.sort()
			this.colors = {}
			_.forEach(cat, (c, cidx) => {
				_this.colors[c] = colorPalette[cidx]
			})
			_this.categories.field.values = _.uniq(_this.categories.field.values)
			if (_this.categories.field.values.length >= limitNbPatientPlots) {
				this.patientPlotWarning = 'Too many (' + _this.categories.field.values.length + ') variables to plot'
				return
			} else this.patientPlotWarning = ''

			_this.getPlotColName(_this.categories.field.values.length)
			// initialise plots //
			_.forEach(_this.categories.field.values, (c0, c0idx) => {
				Vue.set(_this.chartOptions, [c0], {
					tooltip: {
					},
					legend: false,
					emphasis: {
						label: {
							show: false,
							formatter: '{b}: {c}%'
						}
					},
					dataset: [],
					grid: {
						left: '15%',
						right: '15%',
						top: '5%',
						width: '75%',
						height: '65%'
					},
					yAxis: {
						min: 0,
						max: 100,
						type: 'value'
						// name: c0+" "+c1,
						// nameLocation: 'center',
						// nameGap: 30,
						// nameTextStyle: {
						// 	fontSize: 15
						// }
					},
					xAxis: {
						type: 'category',
						data: (_this.mergeSamples) ? ['all ' + _this.categories['sample'].label + 's'] : _this.categories['sample'].values,
						axisLabel: {
							rotate: (_this.mergeSamples) ? 0 : 90
						}

					},
					series: []
				}
				)
			})

			let groups = []
			_.forEach(this.categories.field.values, c0 => {
				groups.push(c0)
			})
			let seriesData = {}
			_.forEach(groups, group => {
				seriesData[group] = { totals: { total: 0 } }
				_.forEach(_this.categories['sample'].values, sample => {
					seriesData[group].totals[sample] = 0
				})
				_.forEach(cellTypes, cT => {
					seriesData[group][cT] = { total: 0 }
					_.forEach(_this.categories['sample'].values, sample => {
						seriesData[group][cT][sample] = 0
					})
				})
			})
			_.forEach(this.plotData, (cols, rowIdx) => {
				let group = (cat0idx > -1) ? cols[cat0idx] : 	_this.categories['sample'].label
				let sample = (sampleIdx > -1) ? cols[sampleIdx] : ((cat0idx > -1) ? cols[cat0idx] :'sample')
				let cT = cols[annotIdx]

				if (cT !== undefined && seriesData[group] !== undefined && seriesData[group][cT] !== undefined) {
					seriesData[group].totals[sample]++
					seriesData[group][cT][sample]++
					seriesData[group][cT]['total']++
					seriesData[group].totals['total']++
				}
			})
			_.forEach(seriesData, (values, group) => {
				_.forEach(values, (samplesData, cT) => {
					if (cT !== 'totals') {
						_.forEach(samplesData, (nb, sample) => {
							seriesData[group][cT][sample] = (seriesData[group].totals[sample]) ? Math.round(nb / seriesData[group].totals[sample] * 10000) / 100 : 0
						})
					}
				})
			})
			_.forEach(seriesData, (values, group) => {
				this.chartOptions[group].series = []
				this.chartOptions[group].legends = []
				let plotSamples = (this.mergeSamples) ? ['total'] : _this.categories['sample'].values
				let validPlotSamples = []
				_.forEach(plotSamples, sample => {
					if (sample === 'total' && this.mergeSamples) validPlotSamples.push(sample)
					else if (+seriesData[group].totals[sample] > 0) validPlotSamples.push(sample)
				})
				_.forEach(cellTypes, cT => {
					if (cT === 'totals') return
					let cTSampleData = []
					_.forEach(validPlotSamples, sample => {
						cTSampleData.push(seriesData[group][cT][sample])
					})
					let series = {
						name: group.replace(/\|$/, '') + ' : ' + cT,
						type: 'bar',
						stack: 'stack',
						itemStyle: {
							color: _this.colors[cT]
						},
						data: cTSampleData
					}
					_this.chartOptions[group].xAxis.data = validPlotSamples
					_this.chartOptions[group].series.push(series)
					_this.chartOptions[group].legends.push(cT)
				})
			})
		},
		download () {
			_.forEach(document.getElementsByClassName('hideDownloads'), e => {
				e.style.display = 'none'
			})
			html2canvas(this.$refs.patientPage, { allowTaint: true, scrollX: 0, scrollY: -window.scrollY }).then(function (canvas) {
				var link = document.createElement('a')

				if (typeof link.download === 'string') {
					link.href = canvas.toDataURL()
					link.download = 'barplots.png'

					// Firefox requires the link to be in the body
					document.body.appendChild(link)

					// simulate click
					link.click()

					// remove the link when done
					document.body.removeChild(link)
				} else {
					window.open()
					// window.open(uri)
  			}
				_.forEach(document.getElementsByClassName('hideDownloads'), e => {
					e.style.display = 'inline-block'
				})
			})
		}
	},
	mounted () {
		let _this = this
		this.dataset = _.find(this.datasets.data, function (d) { return d.unique_id === _this.datasetId })
		this.getData()
	},
	watch: {
		annotation (n, o) {
			if (n && n !== o) {
				this.getData()
			}
		},
		mergeSamples () {
			this.formatPlotData()
		}
	}
}
</script>
<style scoped>
.patientPage {
	display: inline-block;
}

.plotContainer {
	position: relative;
	width: 100% ;
}
.vertical {

	writing-mode: vertical-rl;
	text-orientation: mixed;
	text-align: center;
	padding-top: 20px;
}
</style>
