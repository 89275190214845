<template>
	<div class = 'loading'>
		<div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
			<h4 class = 'text-primary'>loading...</h4>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoadingComponent'
}
</script>
