<template>
<div class="bulkRNA" @mousedown="onMouseDown">
	<h5 class="mt-3 text-center">Explore gene set expression</h5>
	<div class="row">
		<div class="col-2">
			<form @submit.prevent="runAnalysis">
				<div v-if="Object.keys(genesets).length">
					<p class="lead">Select a <a href='https://www.gsea-msigdb.org/' target="_blank">mSigDB</a> geneset:</p>
					<select v-model="genesetCategory" class="form-control">
						<option value="">Gene Set Category...</option>
						<option v-for="cat in Object.keys(genesets)" :key="cat" :value="cat">{{cat}}</option>
					</select>
					<select v-if="genesetCategory" class="form-control" v-model="activegeneset" @change="setGenesFromGeneset">
						<option value="">Gene Set...</option>
						<option v-for="gs in genesets[genesetCategory]" :key="gs.name" :value="gs">{{gs.name}}</option>
					</select>
					<p class="lead my-3">...or enter a list of gene symbols</p>
				</div>
				<textarea v-model="geneList" placeholder="List of gene symbols" class="form-control" rows="20"></textarea>
				<p class="lead my-3">Select an annotation category</p>
				<select class="form-control "  v-model="annotation">
					<option :value="annot" v-for="annot in annotationTypes" :key="annot">annotation {{annot}}</option>
				</select>

				<p class="text-center mt-3"><button type="submit" :disabled="!geneList || analysisRunning" class="btn btn-primary">{{analysisRunning?'Analysis Running...':'Run analysis'}}</button></p>
			</form>
		</div>
		<div class="col-10"  id="bulkRNA">
			<div v-if="mouseIsDown" class="vue-drag-select-box" :style="selectionBoxStyling"></div>

			<p class="alert alert-warning" v-if="unknownGenes.length">{{unknownGenes.length}} genes are absent from this dataset: <span class="badge badge-info mx-1" v-for="ug in unknownGenes" :key="ug">{{ug}}</span></p>
			<p class="alert alert-info"  style="padding-right: 100px; position: relative" v-if="!analysisRunning && genes.length > 0">
				<span v-if="highlightedGenes.length">{{highlightedGenes.length}} genes are highlighted in this dataset: </span>
				<span v-else><v-icon name="info-circle" />&nbsp;Drag selection over the gene axis to select</span>
				<span class="badge badge-light mx-1" v-for="hg in highlightedGenes" :key="hg">
					{{hg}}
					<span class="pointer" @click="removeHighlightedGene(hg)">
						<v-icon name="times" scale="0.8"></v-icon>
					</span>
				</span>
				<button type="button" class="btn text-light btn-link" style="position: absolute; right: 8px; top: 2px" @click="clearHighlightedGenes"><v-icon name="trash" /></button>
				<button type = 'button' :disabled="!highlightedGenes.length" v-clipboard:copy="highlightedGenes.join('\n')" class = 'btn btn-link text-light' v-clipboard:success="copySuccess"  style="position: absolute; right: 35px; top: 2px"><v-icon name="clipboard" /></button>

			</p>
			<div v-if="analysisRunning" style="margin-top: 200px"><loading></loading></div>
			<div :style="`visibility: ${analysisRunning||genes.length===0?'hidden':'visible'}`" id="heatmapChartGeneset"  >
				<chart :options="chartOptionsHeatmap" style="height: 3000px; width:100%; " :autoresize="true" @click="onClick" @mouseover="onMouseOver" ></chart>
			</div>
		</div>
	</div>
	<b-modal id="geneDistModalHeatmap" :title="`${gene} expression distribution in ${datasetId} with ${annotation} annotation`" size="xl" :hide-footer="true">
		<gene-violin :gene='gene' :group='annotation' :annotation='annotation' :dataset='datasetId' :load="true" :ready="false"></gene-violin>
	</b-modal>

</div>
</template>

<script>
import Vue from 'vue'
import loading from '@/components/globals/loading'
import { HTTP } from '@/router/http'
import geneViolin from '@/components/geneViolin'
import { mapGetters } from 'vuex'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

export default {
	name: 'bulkRna',
	props: ['datasetId'],
	components: { loading, geneViolin },
	data () {
		return {
			mouseIsDown: false,
			startPoint: null,
			endPoint: null,
			highlightedGenes: [],
			color: 'rgba(0, 162, 255, .4)',
			annotationTypes: ['CHETAH', 'major', 'minor', 'immune'],
			annotation: 'CHETAH',
			genesetCategory: '',
			genesets: {},
			geneList: '',
			activegeneset: '',
			data: [],
			cellClusters: [],
			unknownGenes: [],
			genes: [],
			gene: '',
			analysisRunning: false,
			chartOptionsHeatmap: {
				tooltip: {
					position: 'top'
				},
				dimensions: [],
				animation: false,
				grid: {
					height: '70%',
					top: '120px',
					left: '300px'
				},
				xAxis: {
					type: 'category',
					data: [],
					axisLabel: {
						formatter (value, idx) {
							if (value.indexOf('blank') > -1) {
								return ''
							} else {
								let parts = value.split('_')
								return (parts[1] === 'print') ? parts[0] : ''
							}
						},
						rotate: '-45',
						interval: 0
					},
					splitArea: {
						show: false
					},
					position: 'top'
				},
				yAxis: {
					type: 'category',
					data: [],
					triggerEvent: true,
					splitArea: {
						show: true
					},
					axisLabel: {
						interval: 0,
						show: true
					}
				},
				visualMap: {
					min: -1,
					max: 10,
					calculable: true,
					show: true,
					orient: 'vertical',
					right: '0',
					top: '0%',
					inRange: {
						// color: ['#2c7bb6', '#abd9e9', '#ffffbf', '#fdae61', '#d7191c']
						color: ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026']
					}
				},
				series: [{
					name: 'CellTypes',
					type: 'heatmap',
					data: [],
					label: {
						show: false
					},
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}]
			}
		}
	},
	methods: {
		getScroll () {
			// If we're on the server, default to 0,0
			if (typeof document === 'undefined') {
				return {
					x: 0,
					y: 0
				}
			}
			return {
				x: this.$el.scrollLeft || document.body.scrollLeft || document.documentElement.scrollLeft,
				y: this.$el.scrollTop || document.body.scrollTop || document.documentElement.scrollTop
			}
		},
		onMouseDown (event) {
			// Ignore right clicks
			if (event.button === 2) {
				return
			}
			// Check if shift is down
			this.concat = event.shiftKey
			// Register begin point
			this.mouseIsDown = true
			this.startPoint = {
				x: event.pageX,
				y: event.pageY
			}
			// Start listening for mouse move and up events
			window.addEventListener('mousemove', this.onMouseMove)
			window.addEventListener('mouseup', this.onMouseUp)
		},
		onMouseMove (event) {
			// Update the end point position
			if (this.mouseIsDown) {
				this.endPoint = {
					x: event.pageX,
					y: event.pageY
				}
			}
		},
		onMouseUp (event) {
			// Clean up event listeners
			window.removeEventListener('mousemove', this.onMouseMove)
			window.removeEventListener('mouseup', this.onMouseUp)
			// Reset state
			this.mouseIsDown = false
			this.concat = false
			this.startPoint = null
			this.endPoint = null
		},
		onClick (evt) {
			if (evt.componentType === 'yAxis' && evt.value) {
				this.gene = evt.value
				this.$bvModal.show('geneDistModalHeatmap')
				// this.$router.push('/scrnaseq/' + evt.value)
			}
		},
		onMouseOver (evt) {
			if (evt.componentType === 'yAxis' && evt.value) {
				if (this.mouseIsDown) {
					const idx = this.highlightedGenes.indexOf(evt.value)
					if (idx === -1) {
						this.highlightedGenes.push(evt.value)
					}
				}
			}
			if (evt.componentType === 'series') {
				if (this.mouseIsDown) {
					const gene = this.chartOptionsHeatmap.yAxis.data[evt.value[1]]
					const idx = this.highlightedGenes.indexOf(gene)
					if (idx === -1) {
						this.highlightedGenes.push(gene)
					}
				}
			}
		},
		removeHighlightedGene (gene) {
			const idx = this.highlightedGenes.indexOf(gene)
			if (idx > -1) {
				this.highlightedGenes.splice(idx, 1)
			}
		},
		clearHighlightedGenes () {
			this.highlightedGenes = []
		},
		copySuccess () {
			this.$snotify.success('Gene list copied successfully')
		},
		setGenesFromGeneset () {
			this.geneList = this.activegeneset.genes.join('\n')
		},
		checkGenes () {
			const _this = this
			this.genes = this.geneList.split('\n').filter(x => _this.dataset_genes.includes(x))
			this.unknownGenes = this.geneList.split('\n').filter(x => !_this.dataset_genes.includes(x))
		},
		runAnalysis () {
			const _this = this
			_this.analysisRunning = true
			HTTP.post('/scrnaseq/' + this.datasetId + '/geneset_analysis', { genes: _this.genes, annotation: _this.annotation }).then(res => {
				_this.analysisRunning = false
				document.getElementById('heatmapChartGeneset').style.height = ((_this.genes.length * 10) + 100) + 'px'
				Vue.nextTick(() => {
					_this.chartOptionsHeatmap.xAxis.data = Object.values(res.data.colnames)
					_this.chartOptionsHeatmap.yAxis.data = Object.values(res.data.rownames)
					_this.chartOptionsHeatmap.series[0].data = res.data.data
					_this.chartOptionsHeatmap.grid.height = ((_this.genes.length * 15) + 'px')
				})
			}).catch(err => {
				_this.analysisRunning = false
				_this.$snotify.error(err)
			})
		}
	},
	computed: {
		...mapGetters({
			datasets: 'scrnaseq_datasets',
			dataset_genes: 'dataset_genes'
		}),
		selectionBox () {
			// Only set styling when necessary
			if (!this.mouseIsDown || !this.startPoint || !this.endPoint) return {}
			const clientRect = document.getElementById('bulkRNA').getBoundingClientRect()
			// const clientRect = this.$el.getBoundingClientRect()
			const scroll = this.getScroll()
			// Calculate position and dimensions of the selection box
			const left = Math.min(this.startPoint.x, this.endPoint.x) - clientRect.left - scroll.x
			const top = Math.min(this.startPoint.y, this.endPoint.y) - clientRect.top - scroll.y
			const width = Math.abs(this.startPoint.x - this.endPoint.x)
			const height = Math.abs(this.startPoint.y - this.endPoint.y)
			// Return the styles to be applied
			return {
				left,
				top,
				width,
				height
			}
		},
		selectionBoxStyling () {
			// Only set styling when necessary
			if (!this.mouseIsDown || !this.startPoint || !this.endPoint) {
				return { background: this.color }
			}
			const { left, top, width, height } = this.selectionBox
			// Return the styles to be applied
			return {
				background: this.color,
				left: `${left}px`,
				top: `${top}px`,
				width: `${width}px`,
				height: `${height}px`
			}
		}
	},
	watch: {
		geneList (n, o) {
			if (n && n !== o) {
				this.geneList = this.geneList.toUpperCase()
				this.checkGenes()
			}
		}
	},
	mounted () {
		const _this = this
		HTTP.get('/genesets').then(res => {
			_this.genesets = res.data
		})
		// let results = json
	},
	beforeDestroy () {
		// Remove event listeners
		window.removeEventListener('mousemove', this.onMouseMove)
		window.removeEventListener('mouseup', this.onMouseUp)
	}

}
</script>
<style>
  .vue-drag-select {
    position: relative;
    user-select: none;
  }
  .vue-drag-select-box {
    position: absolute;
    z-index: 99;
  }
</style>
